import { PrimaryButton } from '@components/buttons/PrimaryButton';
import {
  useCardConfigDataContext,
  useGetSubscriptionConfigData,
} from '@components/cardConfig/CardConfigDataContext';
import CloseIcon from '@mui/icons-material/Close';
import {
  DialogContent,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Grid,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { dusk, eggshell, white } from 'src/constants/colors';
import {
  useGetFusionTopicsData,
  useTopicsDataContext,
} from './TopicsDataContext';
import { useState } from 'react';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import {
  FusionToggleTopicTypeItem,
  FusionTypeBase,
} from '@components/cardConfig/common/TopicTypeItem';
import { FormMessageBar } from '@components/FormMessageBar';
import { ReactComponent as IconQuestion } from '../../assets/icn-question.svg';
import { ReactComponent as CheckIcon } from '../../assets/icn-check-seaglass.svg';
import { Footer } from '@components/footer';
import { ReactComponent as IconChevronDown } from '../../assets/icn-chevron-down.svg';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import CustomIconSelect from './CustomIconSelect';
import { GenericEventIconHint } from 'src/services/gql/generated';
import { FusionEventMetadata } from './CreateNonCMTopicDialog';
import { InputWithLabel } from '@components/input/InputWithLabel';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  handleClose: () => void;
  open: boolean;
  isEdit?: boolean;
  topicId?: string;
  currentTopicName?: string;
  metaData?: FusionEventMetadata;
};

const CreateCMTopicDialog = ({
  handleClose,
  open,
  isEdit = false,
  topicId,
  currentTopicName = '',
  metaData,
}: Props) => {
  const { isAP2Enabled } = useFeatureFlag();
  const [topicName, setTopicName] = useState<string>(
    isEdit ? currentTopicName : '',
  );
  const [cardName, setCardName] = useState<string>('');
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false);
  const [iconUrl, setIconUrl] = useState<string>(
    isEdit ? metaData?.uiConfigOverride?.customIconUrl ?? '' : '',
  );
  const [customIcon, setCustomIcon] = React.useState<
    GenericEventIconHint | string
  >(isEdit ? metaData?.uiConfigOverride?.icon ?? 'Megaphone' : 'Megaphone');
  const { items: configs } = useGetSubscriptionConfigData();
  const { create, update, addFusionEventToFusionSource } =
    useTopicsDataContext();
  const { updateItem } = useCardConfigDataContext();
  const { items: topicsData, refetchFusionTopicsData } =
    useGetFusionTopicsData();

  const { setMessageBarState, clearMessageBarState } =
    useFormMessageBarContext();
  const setErrorMessage = (value: string) => {
    setMessageBarState({ status: 'error', message: value });
  };

  const defaultUiConfigOverride: FusionEventMetadata['uiConfigOverride'] = {
    topicDisplayName: '',
    historyDisplayName: '',
    subscriptionValueOrRef: {
      type: 'value',
      value: [{ label: '', value: '*' }],
    },
  };

  const [uiConfigOverride, setUiConfigOverride] = useState<
    FusionEventMetadata['uiConfigOverride']
  >(
    isEdit
      ? metaData?.uiConfigOverride ?? defaultUiConfigOverride
      : defaultUiConfigOverride,
  );

  const handleSaveTopic = async () => {
    clearMessageBarState();
    if (topicName === '' || topicName === null) {
      return;
    }
    const dupNameTopic = topicsData.find((topic) => topic.name === topicName);
    if (dupNameTopic !== undefined && currentTopicName !== topicName) {
      setErrorMessage('Topic name already exists');
      return;
    }
    let newMetaData = {} as FusionEventMetadata;
    const metadataConfig = {
      icon: customIcon,
      customIconUrl: iconUrl,
      topicDisplayName: uiConfigOverride.topicDisplayName ?? '',
      historyDisplayName: uiConfigOverride.historyDisplayName ?? '',
      subscriptionValueOrRef: {
        type: 'value',
        value: [{ label: '', value: '*' }],
      },
    } as FusionEventMetadata['uiConfigOverride'];

    if (isEdit) {
      if (!topicId) {
        return;
      }
      try {
        setSaveIsLoading(true);
        const defualtMetaData = { filters: [] };
        // add this condition for exsiting cm topics with metaData equal to null
        if (!metaData) {
          newMetaData = { ...defualtMetaData, uiConfigOverride: metadataConfig };
          const metaDataJson = JSON.stringify(newMetaData);
          await update(topicName, topicId, metaDataJson);
        } else {
          const newUiConfigOverride = {
            ...metaData.uiConfigOverride,
            ...metadataConfig,
          };
          newMetaData = { ...metaData, uiConfigOverride: newUiConfigOverride };
          const metaDataJson = JSON.stringify(newMetaData);
          await update(topicName, topicId, metaDataJson);
        }
        const configCards = configs.filter((config) =>
          config.eventTypes.some((event) => event.name === currentTopicName),
        );

        if (!configCards) {
          refetchFusionTopicsData();
          handleClose();
          setSaveIsLoading(false);
          return;
        }
        configCards.map(async (config) => {
          const index = config.eventTypes.findIndex((event) => {
            return event.name === currentTopicName;
          });
          const newEvent = {
            ...config.eventTypes[index],
            name: topicName,
          };
          const newEventTypes = [
            ...config.eventTypes.slice(0, index),
            newEvent,
            ...config.eventTypes.slice(index + 1),
          ];
          const newConfigItem = {
            ...config,
            eventTypes: newEventTypes,
          };
          await updateItem(newConfigItem);
        });
      } catch (err) {
        setErrorMessage((err as Error).message);
        return;
      }
    } else {
      try {
        setSaveIsLoading(true);
        const uiConfigOverride = {
          ...metadataConfig,
        };
        newMetaData = { uiConfigOverride: uiConfigOverride, filters: [] };
        const metaDataJson = JSON.stringify(newMetaData);
        const { id } = await create(topicName, true, metaDataJson);
        await addFusionEventToFusionSource({
          fusionEventId: id,
          fusionSourceId: '00000000000000000000000000000000',
        });
        setTopicName('');
        if (cardName === 'no card' || !cardName) {
          handleClose();
          refetchFusionTopicsData();
          setSaveIsLoading(false);
          return;
        }
        const item = configs.find((config) => config.name === cardName);
        if (!item) {
          setSaveIsLoading(false);
          return Promise.reject('Failed to assiciate card with topic');
        }
        const newCMTopic: FusionTypeBase & FusionToggleTopicTypeItem = {
          type: 'fusion',
          name: topicName,
          tooltipContent: '',
          selectedUIType: 'TOGGLE',
          fusionEventId: {
            type: 'value',
            value: id ?? '',
          },
          sourceAddress: {
            type: 'value',
            value: '*',
          },
          useCustomIcon: false,
        };
        const newEventTypes = [...item.eventTypes, newCMTopic];
        const newConfigItem = {
          ...item,
          eventTypes: newEventTypes,
        };
        try {
          await updateItem(newConfigItem);
        } catch (err) {
          setErrorMessage('Failed to assiciate card with topic');
          return;
        }
      } catch (err) {
        setErrorMessage('Failed to create a topic, please try again');
        return;
      }
    }
    handleClose();
    refetchFusionTopicsData();
    setSaveIsLoading(false);
  };

  const displayNameValidator = () => {
    if (
      uiConfigOverride.topicDisplayName?.length > 0 &&
      uiConfigOverride.historyDisplayName?.length > 0
    ) {
      return false;
    }
    return true;
  };
  return (
    <div>
      <Dialog
        TransitionComponent={Transition}
        fullScreen
        onClose={handleClose}
        open={open}
        sx={{
          '&.MuiPaper-root &.MuiDialog-paper': {
            backgroundColor: 'transparent',
          },
          backgroundColor: '#F3F3FA',
        }}
      >
        <DialogContent
          sx={{
            backgroundColor: '#F3F3FA',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            pb: 0,
          }}
        >
          <IconButton
            sx={{
              backgroundColor: white,
              borderRadius: '45px',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              position: 'absolute',
              right: '15%',
              top: '56px',
              '&:hover': {
                backgroundColor: white,
              },
            }}
            onClick={handleClose}
          >
            <CloseIcon sx={{ color: dusk }} />
          </IconButton>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              mt: 6,
              mb: 8,
              width: '100%',
            }}
          >
            <Typography variant="h1">
              {isEdit ? 'Edit topic' : 'Create a new topic'}
            </Typography>
          </Stack>
          <FormMessageBar />
          <Stack
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '920px',
              backgroundColor: white,
              borderRadius: '8px',
              mb: 3,
              pb: 4,
            }}
          >
            {' '}
            <Typography variant="h2" sx={{ my: 3 }}>
              Community Manager Topic
            </Typography>
            {isEdit ? (
              <InputLabel
                sx={{
                  fontSize: '14px',
                  fontWeight: 700,
                  color: dusk,
                  marginTop: '6px',
                  textAlign: 'left',
                  width: '360px',
                }}
              >
                Name this topic
              </InputLabel>
            ) : null}
            <TextField
              sx={{
                width: '360px',
                mb: '12px',
              }}
              // margin="dense"
              label={isEdit ? '' : 'Name this topic'}
              // placeholder={isEdit ? currentTopicName ?? '' : 'Enter topic name'}
              value={topicName}
              onChange={(e) => {
                clearMessageBarState();
                const value = (e.target as HTMLInputElement).value;
                setTopicName(value);
              }}
            />
            <InputWithLabel
              isEdit={isEdit}
              value={uiConfigOverride?.topicDisplayName ?? ''}
              handleChange={(e) => {
                const nextState = { ...uiConfigOverride };
                nextState.topicDisplayName = e.target.value;
                setUiConfigOverride(nextState);
              }}
              label="Topic display name"
              customSx={{
                width: '360px',
                mb: '12px',
                mt: isEdit ? '0px' : '16px',
              }}
              tooltipContent="This display title is used in the card when users are subscribing, for example: ‘Liquidation Alerts'.
            We recommend these titles be plural."
            />
            <InputWithLabel
              isEdit={isEdit}
              value={uiConfigOverride?.historyDisplayName ?? ''}
              handleChange={(e) => {
                const nextState = { ...uiConfigOverride };
                nextState.historyDisplayName = e.target.value;
                setUiConfigOverride(nextState);
              }}
              label="Topic display title in alert"
              customSx={{
                width: '360px',
                mb: '12px',
                mt: isEdit ? '0px' : '16px',
              }}
              tooltipContent="This display title is used in the alert received, for example: ‘Liquidation Alert’.
            We recommend these titles be singular."
            />
            {configs.length > 0 && !isEdit ? (
              <Stack flexDirection="column">
                <InputLabel
                  id="select-ui-card"
                  sx={{
                    mb: 0.5,
                    fontFamily: 'Rota',
                    fontWeight: 600,
                    fontSize: '14px',
                  }}
                >
                  Select UI card (optional)
                </InputLabel>
                <Select
                  labelId="select-ui-card"
                  label="Select UI card (optional)"
                  value={cardName}
                  onChange={(e) => {
                    setCardName(e.target.value as string);
                    clearMessageBarState();
                  }}
                  IconComponent={(props) => {
                    return (
                      <IconChevronDown
                        {...props}
                        style={{
                          marginRight: '12px',
                          top: 'calc(50% - .7em)',
                          fill: isAP2Enabled ? dusk : white,
                        }}
                      />
                    );
                  }}
                  renderValue={(selected) => {
                    if (selected) {
                      return selected;
                    } else {
                      return 'Select UI card (optional)';
                    }
                  }}
                  sx={{
                    width: '360px',
                    backgroundColor: eggshell,
                    ':before': { border: 'none' },
                    '&.MuiSelect-iconStandard': { color: 'red' },
                    mb: 1,
                  }}
                >
                  {configs.map((item) => {
                    return (
                      <MenuItem
                        value={item.name}
                        key={item.id}
                        sx={{ fontSize: '14px', fontWeight: 550 }}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                  <MenuItem
                    value="no card"
                    sx={{ fontSize: '14px', fontWeight: 550 }}
                  >
                    No card - own frontend client
                  </MenuItem>
                </Select>
              </Stack>
            ) : null}
            <Stack>
              <InputLabel
                sx={{
                  mb: 0.5,
                  fontFamily: 'Rota',
                  fontWeight: 600,
                  fontSize: '14px',
                }}
              >
                Select icon
              </InputLabel>
              <CustomIconSelect
                customIconUrlSx={{ width: '360px' }}
                selectSx={{ width: '360px' }}
                iconUrl={iconUrl}
                setIconUrl={setIconUrl}
                customIcon={customIcon}
                setCustomIcon={setCustomIcon}
              />
            </Stack>
          </Stack>
          <PrimaryButton
            disabled={
              saveIsLoading || topicName.length < 1 || displayNameValidator()
            }
            buttonTitle="Save Topic"
            onClick={handleSaveTopic}
          />

          <Stack sx={{ py: '80px' }} alignItems="center" gap="12px">
            <IconQuestion />
            <Typography variant="h2">
              What is a Community Manager Topic?
            </Typography>
            <Typography
              variant="h3"
              sx={{
                maxWidth: '720px',
                textAlign: 'center',
                color: dusk,
                fontWeight: 700,
              }}
            >
              Create a Community Manager topic that your customers can subscribe
              to. <br />
              You can then use Notifi’s Community Manager to send out
              notifications to your users.
            </Typography>
            <Typography
              sx={{
                color: dusk,
                fontSize: '20px',
                fontWeight: 900,
                lineHeight: 'normal',
                mt: '12px',
                mb: '8px',
              }}
            >
              Community Topic Examples:
            </Typography>
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column',
                background: white,
                borderRadius: '8px',
                padding: '17px 45px',
                gap: '8px',
              }}
            >
              <Stack direction="row" alignItems="center" gap="8px">
                <CheckIcon style={{ marginTop: '-2px' }} />
                <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                  General Announcements
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" gap="8px">
                <CheckIcon style={{ marginTop: '-2px' }} />
                <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                  Product Updates
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" gap="8px">
                <CheckIcon style={{ marginTop: '-2px' }} />
                <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                  Alpha Announcements
                </Typography>
              </Stack>
            </Grid>
          </Stack>
          <Footer sx={{ background: eggshell }} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateCMTopicDialog;
