import { format } from 'date-fns'


export const formatDate = (date: string | number | Date | undefined, f = 'yyyy-MM-dd') => {
  if (!date) return ''
  const d = new Date(date)
  return format(d, f)
}

export function isTokenExpired(expiryDateStr: string, currentDate: Date = new Date()): boolean {
  const expiryDate = new Date(expiryDateStr);
  return currentDate > expiryDate;
}
