import * as React from 'react';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, CircularProgress, DialogContent, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { dusk, eggshell, white } from 'src/constants/colors';
import { useState } from 'react';
import { FormMessageBar } from '@components/FormMessageBar';
import { Footer } from '@components/footer';
import CreateNonCMTopicStep1Card from './CreateNonCMTopicStep1Card';
import OnOffChainTopicLayout from './OnOffChainTopicLayout';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import { FusionEventData } from 'src/services/gql/generated';
import { useOnOffChainFormContext } from './OnOffChainFormContext';
import {
  AlertFilter,
  Filter,
  FilterType,
  FrequencyFilter,
  FusionEventMetadata,
  Transition,
  ValueType,
  convertOldThresholdToNewThresholdType,
} from './CreateNonCMTopicDialog';
import { useGetFusionTopicsData } from './TopicsDataContext';
import { AlertFiltersChangeModal } from '@components/modal/AlertFiltersChangeModal';
import useUpdateEffect from 'src/hooks/useUpdateHook';

type Props = {
  handleClose: () => void;
  open: boolean;
  isEdit?: boolean;
  topic?: FusionEventData;
};

const CreateOnOffChainTopicDialog = ({
  handleClose,
  open,
  isEdit,
  topic,
}: Props) => {
  const metaData = JSON.parse(topic?.metadata ?? '{}');
  const editFilter = metaData?.filters?.find((obj: Filter) =>
    Object.keys(obj).includes('userInputParams'),
  );
  const editFrequency = metaData?.filters?.find((obj: Filter) =>
    Object.keys(obj).includes('minimumDurationBetweenTriggersInMinutes'),
  );

  const isUserInputParamsWithRadioTypeandCorrectOrder = editFilter
    ? editFilter?.userInputParams[0]?.uiType === 'radio'
    : true;

  const thresholdType = convertOldThresholdToNewThresholdType[
    editFilter?.userInputParams[
      isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
    ].kind
  ] as ValueType;

  const variableType = convertOldThresholdToNewThresholdType[
    editFilter?.requiredParserVariables[0].variableType
  ] as ValueType;

  if (editFilter) {
    editFilter.userInputParams[
      isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
    ].kind = thresholdType;
    editFilter.requiredParserVariables[0].variableType = variableType;
  }

  const defaultFilters: AlertFilter = {
    name: '',
    description: '',
    type: 'AlertFilter' as FilterType,
    executionPriority: 20,
    userInputParams: [
      {
        name: 'thresholdDirection',
        kind: 'string',
        uiType: 'radio',
        options: [],
        allowCustomInput: false,
        defaultValue: 'above',
        description: 'Alert me when value is above:',
        prefixAndSuffix: { prefix: '', suffix: '' },
      },
      {
        name: 'threshold',
        kind: 'integer',
        uiType: 'button',
        options: [],
        allowCustomInput: false,
        defaultValue: '',
        description: '',
        prefixAndSuffix: { prefix: '', suffix: '' },
        customInputConstraints: {
          maxDecimalPlaces: undefined,
          upperBound: undefined,
          lowerBound: undefined,
        },
      },
    ],
    requiredParserVariables: [
      {
        variableName: 'thresholdComparisonValue',
        variableType: '' as ValueType,
        variableDescription:
          'This value must be output by the parser, as it is the value to be compared against the threshold value provided by the user in the alert filter stage to decide on sending out the notification or not.',
      },
    ],
  };

  const defaultFrequency: FrequencyFilter = {
    name: 'frequency',
    minimumDurationBetweenTriggersInMinutes: isEdit
      ? editFrequency?.minimumDurationBetweenTriggersInMinutes ?? -1
      : -1,
    executionPriority: 10,
    description:
      'Filter used to debounce or set a max frequency of alerts. Max period is 24 hours.',
    type: 'FrequencyAlertFilter',
    requiredParserVariables: [
      {
        variableName: 'debounceKey',
        variableType: 'string',
        variableDescription:
          'This is the key used to debounce alerts. If this is a normal case where an alert is triggered by a single condition such as a particular wallet balance being above a certain threshold, then you can simply pass *. If the alert is triggered for multiple cases, and you would like the debounce applied per case, pass in the account id, index or anything that would differentiate one alert trigger from another. Max character length is 16 chars.',
      },
    ],
  };
  const defaultUiConfigOverride: FusionEventMetadata['uiConfigOverride'] = {
    topicDisplayName: '',
    historyDisplayName: '',
    subscriptionValueOrRef: {
      type: 'ref',
      ref: 'walletAddress',
    },
  };
  const [topicName, setTopicName] = useState<string>(
    isEdit ? topic?.name ?? '' : '',
  );
  const [step, setStep] = useState<number>(1);
  const [userPresetThresholdsEnabled, setUserPresetThresholdsEnabled] =
    React.useState(false);
  const [filter, setFilter] = useState<AlertFilter>(
    isEdit ? editFilter ?? defaultFilters : defaultFilters,
  );
  const [frequency, setFrequency] = useState<FrequencyFilter>(
    isEdit ? editFrequency ?? defaultFrequency : defaultFrequency,
  );
  const [uiConfigOverride, setUiConfigOverride] = useState<
    FusionEventMetadata['uiConfigOverride']
  >(
    isEdit
      ? metaData.uiConfigOverride ?? defaultUiConfigOverride
      : defaultUiConfigOverride,
  );
  const { setMessageBarState, clearMessageBarState } =
    useFormMessageBarContext();
  const setErrorMessage = (value: string) => {
    setMessageBarState({ status: 'error', message: value });
  };  
  const { refetchFusionTopicsData } = useGetFusionTopicsData();
  const {
    setFusionEventData,
    isSaveTopicButtonEnabled,
    loading,
    handleSaveTopicData,
  } = useOnOffChainFormContext();

  const [isAlertModalChangeVisible, setIsAlertModalChangeVisible] = useState(false)
  const hasUserAcknowledged = React.useRef(false)

  useUpdateEffect(() => {
    if(isEdit && !hasUserAcknowledged.current) {
      setIsAlertModalChangeVisible(true)
    }
  } , [filter])

  const handleAlertModalChangeClose = () => {
    setIsAlertModalChangeVisible(false)
    hasUserAcknowledged.current = true
  }
  

  const upperBound =
    filter.userInputParams[
      isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
    ].customInputConstraints?.upperBound ?? undefined;

  const lowerBound =
    filter.userInputParams[
      isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
    ].customInputConstraints?.lowerBound ?? undefined;

  const isUserRequiredInfoComplete = () => {
    if (uiConfigOverride.subscriptionValueOrRef.type === 'value') {
      return true;
    } else if (uiConfigOverride.subscriptionValueOrRef.type === 'ref') {
      return (
        uiConfigOverride.subscriptionValueOrRef.ref &&
        uiConfigOverride.subscriptionValueOrRef.ref.length > 0
      );
    } else {
      return false;
    }
  };

  const isBoundValueValid = () => {
    if (
      filter.userInputParams[
        isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
      ].allowCustomInput
    ) {
      return typeof upperBound === 'number' && typeof lowerBound === 'number';
    }
    return true;
  };

  const isNextStepEnabled =
    topicName.length > 0 &&
    uiConfigOverride.topicDisplayName?.length > 0 &&
    uiConfigOverride.historyDisplayName?.length > 0 &&
    isUserRequiredInfoComplete() &&
    isBoundValueValid() &&
    (filter.name.length === 0 ||
      (filter.name.length > 0 &&
        filter.description.length > 0 &&
        (userPresetThresholdsEnabled
          ? filter.userInputParams[
              isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
            ].options.length >= 2 &&
            filter.userInputParams[
              isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
            ].options[0] &&
            filter.userInputParams[
              isUserInputParamsWithRadioTypeandCorrectOrder ? 1 : 0
            ].options[1]
          : true)));

  const handleSaveFusionEvent = async () => {
    clearMessageBarState();
    isBoundValueValid() ? null : setErrorMessage('Please enter valid bounds');
    let newMetaData = {};
    if (frequency.minimumDurationBetweenTriggersInMinutes === -1) {
      if (filter.name.length === 0) {
        newMetaData = { uiConfigOverride, filters: [] };
      } else {
        newMetaData = { uiConfigOverride, filters: [filter] };
      }
    } else {
      if (filter.name.length === 0) {
        newMetaData = { uiConfigOverride, filters: [frequency] };
      } else {
        newMetaData = { uiConfigOverride, filters: [filter, frequency] };
      }
    }
    if (!isEdit) {
      const metaDataJson = JSON.stringify(newMetaData);
      setFusionEventData({
        name: topicName,
        isCommunityManagerEvent: undefined,
        metaDataJson,
      });
      refetchFusionTopicsData();

      setStep(2);
    } else {
      // newMetaData = { ...metaData, ...newMetaData };
      // const metaDataJson = JSON.stringify(newMetaData);
      // update(topicName, topic?.id ?? '', metaDataJson);
      setStep(2);
    }
  };

  const submitData = async () => {
    if (!isSaveTopicButtonEnabled) return;

    const response = await handleSaveTopicData();
    refetchFusionTopicsData();
    if (response) {
      handleClose();
      clearMessageBarState();
    }
  };

  const refToTop = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    refToTop.current &&
      refToTop.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [step]);

  return (
    <div>
      <Dialog
        TransitionComponent={Transition}
        fullScreen
        onClose={handleClose}
        open={open}
        sx={{
          '&.MuiPaper-root &.MuiDialog-paper': {
            backgroundColor: 'transparent',
          },
          backgroundColor: '#F3F3FA',
        }}
      >
        <DialogContent
          sx={{
            backgroundColor: '#F3F3FA',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 0,
          }}
        >
          <Stack
            ref={refToTop}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Stack width={'100%'} mt={6} mb={5}>
              <Stack
                display="flex"
                flexDirection="row"
                alignItems="center"
                width="100%"
                gap="20px"
              >
                <IconButton
                  sx={{
                    ...(step === 1 && { visibility: 'hidden' }),
                    flexShrink: 0,
                    backgroundColor: white,
                    borderRadius: '45px',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: white,
                    },
                  }}
                  onClick={() => setStep(step - 1)}
                >
                  <ArrowBackIcon sx={{ color: dusk }} />
                </IconButton>

                <Stack
                  alignItems="center"
                  justifyContent="center"
                  flexGrow="1"
                  flexShrink="1"
                >
                  <Typography variant="h1">
                    {isEdit ? 'Edit topic' : 'Create a new topic'}
                  </Typography>
                </Stack>

                <IconButton
                  sx={{
                    flexShrink: 0,
                    backgroundColor: white,
                    borderRadius: '45px',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: white,
                    },
                  }}
                  onClick={() => {
                    handleClose();
                    clearMessageBarState();
                    setFilter(defaultFilters);
                    setFrequency(defaultFrequency);
                  }}
                >
                  <CloseIcon sx={{ color: dusk }} />
                </IconButton>
              </Stack>
              <Stack
                  alignItems="center"
                  justifyContent="center"
                  flexGrow="1"
                  flexShrink="1"
                >
                  <Typography variant="h2">
                  Notifi monitors on or off-chain activity to send alert
                  </Typography>
                </Stack>
              {isEdit ? null : (
                <Stack
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent={'center'}
                  width="100%"
                  gap="20px"
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 700,
                      color: dusk,
                      mt: '4px',
                    }}
                  >
                    {step === 1 ? 'Step 1 of 2' : 'Step 2 of 2'}
                  </Typography>
                </Stack>
              )}
            </Stack>

            <FormMessageBar />

            {loading ? (
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  zIndex: '100',
                }}
              >
                <CircularProgress size="50px" thickness={4} />
              </Box>
            ) : null}

            {step === 1 ? (
              <CreateNonCMTopicStep1Card
                formHeader="Notifi monitors on or off-chain activity to send alert"
                topicName={topicName}
                setTopicName={setTopicName}
                filter={filter}
                setFilter={setFilter}
                frequency={frequency}
                setFrequency={setFrequency}
                isEdit={isEdit}
                uiConfigOverride={uiConfigOverride}
                setUiConfigOverride={setUiConfigOverride}
                userPresetThresholdsEnabled={userPresetThresholdsEnabled}
                setUserPresetThresholdsEnabled={setUserPresetThresholdsEnabled}
              />
            ) : (
              <OnOffChainTopicLayout  topicName={topicName} filter={filter}  uiConfigOverride={uiConfigOverride}/>
            )}

            <PrimaryButton
              customSx={{ width: '156px', mb: 4 }}
              disabled={
                loading ||
                (step === 1 ? !isNextStepEnabled : !isSaveTopicButtonEnabled)
              }
              buttonTitle={step === 1 ? 'Next' : 'Save Topic'}
              onClick={step === 1 ? handleSaveFusionEvent : submitData}
            />
          </Stack>
          <Footer sx={{ background: eggshell }} />
        </DialogContent>
      </Dialog>
      <AlertFiltersChangeModal open={isAlertModalChangeVisible} handleClose={handleAlertModalChangeClose} />
    </div>
  );
};

export default CreateOnOffChainTopicDialog;
