import {
  Box,
  Button,
  Checkbox,
  Chip,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as DownloadButton } from '../../assets/btn-download.svg';
import { dusk, midnight, seaglassDarker, white } from 'src/constants/colors';
import { ConfirmDialog } from '@components/cardConfig/ConfirmDialog';
import { ReactComponent as IconChevronDown } from '../../assets/icn-chevron-down.svg';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import {
  TenantStatisticalReportType,
  useAvailableTenantStatisticalReportsQuery,
  useUrlsForTenantStatisticalReportsLazyQuery,
} from 'src/services/gql/generated';

const reportTypeList = [
  {
    label: 'Detailed Subscriber List',
    value: TenantStatisticalReportType.SUBSCRIBER_LIST,
  },
  {
    label: 'Detailed Notification History',
    value: TenantStatisticalReportType.MESSAGE_DELIVERY_REPORT,
  },
];

export const DetailedReportDownloadButton = () => {
  const [reportModal, setReportModal] = useState<boolean>(false);
  const [reportType, setReportType] = useState<TenantStatisticalReportType>(
    TenantStatisticalReportType.SUBSCRIBER_LIST,
  );
  const [selectedMonths, setSelectedMonths] = useState<string[]>([]);
  const { setMessageBarState, clearMessageBarState } =
    useFormMessageBarContext();

  const { data } = useAvailableTenantStatisticalReportsQuery({
    variables: {
      input: {
        reportTypes: [
          TenantStatisticalReportType.MESSAGE_DELIVERY_REPORT,
          TenantStatisticalReportType.SUBSCRIBER_LIST,
        ],
      },
    },
  });
  const availableMonths = (data?.availableTenantStatisticalReports?.nodes ?? [])
    .filter((v) => v.reportType === reportType)
    .sort(
      (a, b) =>
        new Date(b.startTime).getTime() - new Date(a.startTime).getTime(),
    );

  const [getCSVDownloadUrls] = useUrlsForTenantStatisticalReportsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const handleModalStatus = (arg: 'Switch' | 'Close') => {
    clearMessageBarState();
    if (arg === 'Switch') {
      if (!reportModal) setReportModal(true);
      else {
        setReportModal(!reportModal);
      }
      return;
    }

    if (arg === 'Close') {
      setSelectedMonths([]);
      setReportModal(false);
    }
  };

  const handleSelectedMonthsChange = (value: string | string[]) => {
    setSelectedMonths(typeof value === 'string' ? value.split(',') : value);
  };

  const handleDownload = useCallback(async () => {
    try {
      const res = await getCSVDownloadUrls({
        variables: {
          input: {
            reportIds: selectedMonths,
          },
        },
      });

      const urls =
        res.data?.urlsForTenantStatisticalReports.preSignedUrlsForReportIds ??
        [];

      const downloadCSV = (url: string) => {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);
        iframe.onload = () => {
          document.body.removeChild(iframe);
        };
      };

      urls.forEach((month) => {
        downloadCSV(month.value);
      });
    } catch (err) {
      if (err instanceof Error) {
        setMessageBarState({ status: 'error', message: err.message });
      }
    }
  }, [getCSVDownloadUrls, selectedMonths, setMessageBarState]);

  const reportTypeSelectionUI = () => {
    return (
      <Stack
        display="flex"
        flexDirection={'column'}
        justifyContent="center"
        alignItems="center"
      >
        <Stack display={'block'} width={'360px'} mt={'13px'}>
          {/* Report Type */}
          <Stack flexDirection="column">
            <InputLabel
              id="blockchain-info"
              sx={{
                mb: 0.125,
                fontFamily: 'Rota',
                fontWeight: 700,
                fontSize: '14px',
              }}
            >
              Report Type
            </InputLabel>

            <Select
              value={reportType}
              onChange={(e) => {
                setReportType(e.target.value as TenantStatisticalReportType);
              }}
              IconComponent={(props) => {
                return (
                  <IconChevronDown
                    {...props}
                    style={{
                      marginRight: '12px',
                      top: 'calc(50% - .7em)',
                      fill: dusk,
                    }}
                  />
                );
              }}
            >
              {reportTypeList.map(({ label, value }, i) => (
                <MenuItem key={i} value={value}>
                  <Stack
                    gap={'14px'}
                    flexDirection={'row'}
                    alignItems={'center'}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Rota',
                        fontSize: '16px',
                        fontWeight: 600,
                        color: midnight,
                      }}
                    >
                      {label}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </Stack>

          {/* Select Month */}
          <Stack flexDirection="column" mt={3}>
            <InputLabel
              id="blockchain-info"
              sx={{
                mb: 0.125,
                fontFamily: 'Rota',
                fontWeight: 700,
                fontSize: '14px',
                display: selectedMonths.length ? 'auto' : 'none',
              }}
            >
              Select Month
            </InputLabel>

            <Select
              multiple
              value={selectedMonths}
              onChange={(e) => {
                handleSelectedMonthsChange(e.target.value);
              }}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0)
                  return (
                    <Typography
                      sx={{
                        fontFamily: 'Rota',
                        fontSize: '16px',
                        fontWeight: 600,
                        color: dusk,
                        mt: '2px',
                      }}
                    >
                      Select Months
                    </Typography>
                  );

                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={availableMonths
                          .find(({ id }) => id === value)
                          ?.name.split('-')?.[0]
                          ?.trim()}
                        sx={{
                          backgroundColor: seaglassDarker,
                          color: white,
                        }}
                      />
                    ))}
                  </Box>
                );
              }}
              IconComponent={(props) => {
                return (
                  <IconChevronDown
                    {...props}
                    style={{
                      marginRight: '12px',
                      top: 'calc(50% - .7em)',
                      fill: dusk,
                    }}
                  />
                );
              }}
            >
              {availableMonths.map(({ name, id }) => (
                <MenuItem key={id} value={id}>
                  <Checkbox
                    checked={selectedMonths.indexOf(id) > -1}
                    sx={{
                      py: 0,
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: 'Rota',
                      fontSize: '14px',
                      fontWeight: 700,
                      color: midnight,
                    }}
                  >
                    {name.split('-')?.[0]}
                  </Typography>
                </MenuItem>
              ))}

              {availableMonths?.length > 0 ? null : (
                <Typography
                  px={2.8}
                  py={4}
                  sx={{
                    fontFamily: 'Rota',
                    fontSize: '14px',
                    fontWeight: 700,
                    color: dusk,
                    textAlign: 'center',
                  }}
                >
                  No Records Found
                </Typography>
              )}
            </Select>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  useEffect(() => {
    setSelectedMonths([]);
  }, [reportType]);

  return (
    <div>
      <Button
        onClick={() => handleModalStatus('Switch')}
        sx={{
          textTransform: 'none',
          fontFamily: 'Rota',
          fontSize: '16px',
          fontWeight: 800,
          color: white,
        }}
      >
        Detailed Report (CSV)
        <DownloadButton style={{ marginLeft: '10px' }} />
      </Button>

      <ConfirmDialog
        disabled={Boolean(!selectedMonths.length)}
        ctaTitle="Download"
        handleClose={() => handleModalStatus('Close')}
        handleConfirm={() => {
          handleDownload();
        }}
        sx={{
          px: 3,
          py: 3,
          pb: 4,
          width: '600px',
        }}
        open={reportModal}
        titleSx={{
          color: midnight,
          fontSize: '18px',
          p: 0,
        }}
        title={'Download Detailed Report'}
      >
        {reportTypeSelectionUI()}
      </ConfirmDialog>
    </div>
  );
};

export default DetailedReportDownloadButton;
