import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import { Button, SxProps } from '@mui/material';
import React from 'react';
import { midnight, seaglassDarker } from 'src/constants/colors';

type Props = {
  buttonTitle: string;
  customSx?: SxProps;
  onClick?: () => void;
  disabled?: boolean;
  startIcon?: React.ReactNode;
};

export const SecondaryButton: React.FC<Props> = ({
  buttonTitle,
  customSx,
  onClick,
  disabled,
  startIcon,
}: Props) => {
  const { isAP2Enabled } = useFeatureFlag();
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="outlined"
      startIcon={startIcon}
      sx={{
        fontFamily: 'Rota',
        fontSize: '16px',
        fontWeight: 700,
        textTransform: 'none',
        '&.Mui-disabled': {
          border: `1px solid ${isAP2Enabled ? midnight : seaglassDarker}`,
          color: isAP2Enabled ? midnight : seaglassDarker,
          opacity: 0.4,
        },
        height: '40px',
        ...customSx,
      }}
    >
      {buttonTitle}
    </Button>
  );
};
