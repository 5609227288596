import { PreviewButton } from '@components/buttons/PreviewButton';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import ContentEditor from '@components/content-editor';
import { Divider, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  cloud,
  dusk,
  eggshell,
  midnight,
  midnightDarker,
} from 'src/constants/colors';
import {
  OptionsType,
  isEmptyContent,
} from 'src/pages/Community/CreateMessageForm';
import { MessageVariables } from 'src/pages/Community/MessageService';
import {
  TemplateType,
  WalletBlockchain,
  useFusionPreviewCommunityManagerContentLazyQuery,
  usePreviewCommunityManagerContentLazyQuery,
} from 'src/services/gql/generated';
import PreviewDialog from 'src/pages/Community/PreviewDialog';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import TurndownService from 'turndown';
type WalletAlertComposerProps = Readonly<{
  walletAlertMessageContent: string;
  setWalletAlertMessageContent: (walletAlertMessageContent: string) => void;
  selectedTopicKey?: string;
  isDefineTemplate?: boolean;
  isCommunityManager: boolean;
}>;

const options: OptionsType = {
  emDelimiter: '*',
  strongDelimiter: '**',
};
const turndownService = new TurndownService(options);

export const WalletAlertComposer: React.FC<WalletAlertComposerProps> = ({
  walletAlertMessageContent,
  setWalletAlertMessageContent,
  selectedTopicKey,
  isDefineTemplate,
  isCommunityManager,
}) => {
  const { isAP2Enabled } = useFeatureFlag();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
  const [walletAlertTemplate, setWalletAlertTemplate] = useState<string>('');

  const [getContent] = usePreviewCommunityManagerContentLazyQuery();
  const [getFusionPreviewContent] =
    useFusionPreviewCommunityManagerContentLazyQuery();

  const { setMessageBarState, clearMessageBarState } =
    useFormMessageBarContext();
  const setErrorMessage = (value: string) => {
    setMessageBarState({ status: 'error', message: value });
  };

  const timestamp = Math.round(Date.now() / 1000);
  const isEnabled =
    !isEmptyContent(walletAlertMessageContent) && selectedTopicKey !== '';

  const handleClosePreviewModal = () => {
    setIsPreviewModalOpen(false);
  };

  const handlePreview = async () => {
    clearMessageBarState();
    const variables: MessageVariables[] = [
      {
        key: 'message',
        value: turndownService
          .turndown(walletAlertMessageContent)
          .replace(/\n\s*\n/g, '\n'),
      },
    ];
    const queryVariables = {
      previewInput: {
        previewTarget: TemplateType.WEB3,
        sourceAddress: selectedTopicKey ?? '',
        timestamp,
        variables,
        walletBlockchain: WalletBlockchain.OFF_CHAIN,
      },
    };
    const { data, error, loading } = await getContent({
      variables: queryVariables,
    });
    if (!loading && data) {
      setIsPreviewModalOpen(true);
      const { previewCommunityManagerContent } = data;
      setWalletAlertTemplate(previewCommunityManagerContent.content);
    } else if (error) {
      setErrorMessage('There was an error generating a preview. Try again!');
    }
  };

  const handleFusionPreview = async () => {
    clearMessageBarState();
    const variables: MessageVariables[] = [
      {
        key: 'message',
        value: turndownService
          .turndown(walletAlertMessageContent)
          .replace(/\n\s*\n/g, '\n'),
      },
    ];
    const queryVariables = {
      fusionCommunityManagerPreviewInput: {
        previewTarget: TemplateType.WEB3,
        targetTemplateId: selectedTopicKey ?? '',
        variables,
      },
    };
    const { data, error, loading } = await getFusionPreviewContent({
      variables: queryVariables,
    });
    if (!loading && data) {
      setIsPreviewModalOpen(true);
      const { fusionPreviewCommunityManagerContent } = data;
      setWalletAlertTemplate(fusionPreviewCommunityManagerContent.content);
    } else if (error) {
      setErrorMessage('There was an error generating a preview. Try again!');
    }
  };

  return (
    <>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack>
          <Typography
            sx={{
              fontFamily: 'Rota',
              fontSize: '18px',
              fontWeight: 700,
              lineHeight: '18px',
              mt: 3,
            }}
          >
            Wallet
          </Typography>
          <Typography
            sx={{
              color: dusk,
              fontFamily: 'Rota',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
              mt: 2,
            }}
          >
            {isCommunityManager ? 'Content' : 'Plain Text Content'}
          </Typography>
        </Stack>
        {isDefineTemplate ? null : (
          <PreviewButton
            isDefineTemplate={isDefineTemplate}
            handlePreview={isAP2Enabled ? handleFusionPreview : handlePreview}
            disabled={!isEnabled}
          />
        )}
      </Stack>
      {isCommunityManager ? (
        <ContentEditor
          setValue={setWalletAlertMessageContent}
          value={walletAlertMessageContent}
          destination="walletAlert"
          isCommunityManager={isCommunityManager}
        />
      ) : (
        <TextField
          color="secondary"
          disabled={false}
          fullWidth
          hiddenLabel
          id="standard-multiline-static"
          inputProps={{
            py: 1.5,
          }}
          multiline
          onChange={(e) => {
            setWalletAlertMessageContent(
              e.target.value.replace(/\n\s*\n/g, '\n'),
            );
          }}
          placeholder="Insert your content..."
          minRows={5}
          size="small"
          sx={{
            '& fieldset': { borderRadius: '4px' },
            '& textarea': {
              fontFamily: 'Rota',
              fontWeight: 600,
              fontSize: '16px',
            },
            ...(isAP2Enabled
              ? {
                  '& .MuiInputBase-root': {
                    color: midnight,
                  },
                  mb: 3,
                  backgroundColor: eggshell,
                }
              : { mb: 3, backgroundColor: midnightDarker }),
          }}
          value={walletAlertMessageContent}
          variant="outlined"
        />
      )}
      {isDefineTemplate ? null : (
        <Divider
          sx={{
            borderColor: isAP2Enabled ? cloud : 'rgba(255, 255, 255, 0.12)',
            borderWidth: isAP2Enabled ? '1.5px' : '1px',
          }}
        />
      )}
      <PreviewDialog
        destination="walletAlert"
        template={walletAlertTemplate}
        handleClose={handleClosePreviewModal}
        open={isPreviewModalOpen}
      />
    </>
  );
};
