import { Button, SxProps } from '@mui/material';
import React from 'react';

type Props = {
  buttonTitle: string;
  customSx?: SxProps;
  disabled?: boolean;
  onClick?: () => void;
  startIcon?: React.ReactNode;
};

export const PrimaryButton: React.FC<Props> = ({
  buttonTitle,
  customSx,
  disabled,
  onClick,
  startIcon,
}: Props) => {
  return (
    <Button
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
      variant="contained"
      startIcon={startIcon}
      sx={{
        height: '40px',
        textTransform: 'none',
        ...customSx,
      }}
    >
      {buttonTitle}
    </Button>
  );
};
