import { useCallback, useMemo, useState } from 'react';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import {
  BatchBroadcastMessageInput,
  ListTemplatesDocument,
  TemplateType,
  useBatchBroadcastMessageMutation,
  useUploadTemplateMutation,
} from 'src/services/gql/generated';
import { DataPayloadFusionBroadcast } from 'src/services/rest/types';
import { useNotifiEnv } from '@components/auth/NotifiEnvContext';
import { generateDataplaneGatewayApiUrl } from '@components/auth/envUtils';
import { useAuthContext } from '@components/auth/AuthContext';
import { isTokenExpired } from 'src/util/dateUtils';

export type KeyType =
  | 'type'
  | 'subject'
  | 'message'
  | 'TargetCollection'
  | 'body'
  | 'message__markdown'
  | 'notifiCampaignId';

export type MessageVariables = { key: KeyType; value: string };

export type CustomHtmlPayload = {
  htmlMessage: string;
  unstyledMessage: string;
};

export function noop() {
  /* no-op */
}

export function throwError(msg: string) {
  throw new Error(msg);
}

export interface CallbackOptions<T> {
  onError: (msg: string) => void;
  onSuccess: ((val?: T) => void) | (() => void);
}

export type TypeSafeOmit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

const DEFAULT_CALLBACK_OPTIONS: CallbackOptions<unknown> = {
  onError: throwError,
  onSuccess: noop,
};

export default function useMessageService() {
  const { setMessageBarState } = useFormMessageBarContext();
  const [createBroadcast] = useBatchBroadcastMessageMutation();
  const { notifiEnv } = useNotifiEnv();
  const [uploadImpl] = useUploadTemplateMutation();
  const authState = useAuthContext()
  const normalPermissionsTokenKey = useMemo(() => `config-tool:${notifiEnv}:normalPermissionToken`, [notifiEnv]);
  const normalPermissionsTokenExpiryKey = useMemo(() => `${normalPermissionsTokenKey}:expiry`, [normalPermissionsTokenKey]);
  const refreshTokenKey = useMemo(() => `config-tool:${notifiEnv}:refreshToken`, [notifiEnv]);
  const [upgradeAccountModal, setUpgradeAccountModal] = useState<{
    isVisible: boolean;
    data?: string;
  }>({ isVisible: false });
  
  const createBroadcastMessage = useCallback(
    async (
      payload: BatchBroadcastMessageInput,
      options = DEFAULT_CALLBACK_OPTIONS,
    ): Promise<string> => {
      const setErrorMessage = (value: string) => {
        setMessageBarState({ status: 'error', message: value });
      };
      const { onError, onSuccess } = options;
      let broadcastMessageId = '';

      try {
        const res = await createBroadcast({
          variables: {
            input: payload,
          },
        });
        broadcastMessageId = res?.data?.batchBroadcastMessage?.id ?? '';
        onSuccess(res);
      } catch (e) {
        setErrorMessage((e as Error).message);
        onError;
      }

      return broadcastMessageId;
    },
    [createBroadcast, setMessageBarState],
  );

  const createFusionBroadcastMessage = useCallback(
    async (
      payload: DataPayloadFusionBroadcast,
      options = DEFAULT_CALLBACK_OPTIONS,
    ): Promise<string> => {
      const setErrorMessage = (value: string) => {
        setMessageBarState({ status: 'error', message: value });
      };
      const { onError, onSuccess } = options;
      let broadcastMessageId = '';
      const apiUrl = generateDataplaneGatewayApiUrl(notifiEnv);
      try {
        // The token refresh mechanism is implemented here without using an interceptor,
        // as this is the only place where a REST API is used instead of GraphQL.
        // Implementing an interceptor for a single API endpoint would be excessive.
        // If additional REST endpoints are introduced in the future, 
        // a global API client with interceptor capabilities can be considered.

        let elevatedPermissionsToken;
        let exisitingNormalPermissionsToken = localStorage.getItem(normalPermissionsTokenKey);
        const normalPermissionsTokenExpiry = localStorage.getItem(normalPermissionsTokenExpiryKey);
        const exisitingRefreshToken = localStorage.getItem(refreshTokenKey);
        if (!exisitingRefreshToken) {
          authState.type === 'loggedIn' && authState.logOut()
          return broadcastMessageId
        }
        if ((normalPermissionsTokenExpiry && isTokenExpired(normalPermissionsTokenExpiry))) {
          const { normalPermissionToken, refreshToken, normalPermissionsTokenExpiry } = await authState.generateNormalPermissionsToken(exisitingRefreshToken)
          localStorage.setItem(refreshTokenKey, refreshToken);
          localStorage.setItem(normalPermissionsTokenKey, normalPermissionToken);
          localStorage.setItem(normalPermissionsTokenExpiryKey, normalPermissionsTokenExpiry);
          exisitingNormalPermissionsToken = normalPermissionToken
          if (authState.type === 'loggedIn') {
            authState.setNormalPermissionToken(normalPermissionToken);
          }
        }
        if (authState.type === 'loggedIn' && exisitingNormalPermissionsToken) {
          const token = await authState.generateElevatedPermissionsAuthorizationToken(exisitingNormalPermissionsToken)
          elevatedPermissionsToken = token.elevatedPermissionsToken
        }

        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${elevatedPermissionsToken}`,
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          if (response.status === 429) {
            setUpgradeAccountModal({ isVisible: true, data: await response.text() });
          }
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        broadcastMessageId = data?.data?.batchBroadcastMessage?.id ?? '';
        onSuccess(data);
      } catch (e) {
        setErrorMessage((e as Error).message);
        onError((e as Error).message || '');
      }

      return broadcastMessageId;
    },
    [notifiEnv, setMessageBarState, authState],
  );

  const uploadTemplate = useCallback(
    async (templateName: string, template: string, type: TemplateType) => {
      const setErrorMessage = (value: string) => {
        setMessageBarState({ status: 'error', message: value });
      };

      try {
        await uploadImpl({
          variables: {
            templateName,
            template,
            type,
          },
          refetchQueries: [
            {
              query: ListTemplatesDocument,
              variables: { ListTemplatesInput: { templateName } },
            },
          ],
        });
      } catch (e) {
        setErrorMessage((e as Error).message);
      }
    },
    [setMessageBarState, uploadImpl],
  );

  return {
    createBroadcastMessage,
    createFusionBroadcastMessage,
    uploadTemplate,
    setUpgradeAccountModal,
    upgradeAccountModal,
  };
}
