import * as React from 'react';
import {
  createNewParser,
  useOnOffChainFormContext,
} from './OnOffChainFormContext';
import { WalletBlockchain } from 'src/services/gql/generated';
import useFrequencyByUnit, {
  covertCronToFrequencyAndUnit,
} from 'src/hooks/useFrequencyByUnit';
import CronInput from './CronInput';
import { Typography } from '@mui/material';

const CreateOffChainTopicStep2 = () => {
  const { frequency, unit, setFrequency, setUnit, cronExecutionInterval } =
    useFrequencyByUnit();
  const [cronExecution, setCronExecution] = React.useState<any>(null);

  const {
    selectedParser,
    parserName,
    setIsSaveTopicButtonEnabled,
    setFusionSourceData,
  } = useOnOffChainFormContext();

  // It'll keep the local form state in sync with the Form-Context-Provider.
  React.useEffect(() => {
    if (!frequency || (selectedParser.id === createNewParser && !parserName)) {
      setIsSaveTopicButtonEnabled(false);
      return;
    }

    const filter = { CronExecutionInterval: cronExecutionInterval };
    const fusionSourceData = {
      name: parserName,
      blockchainType: WalletBlockchain.OFF_CHAIN,
      filter: JSON.stringify(filter),
    };

    setFusionSourceData(fusionSourceData);
    setIsSaveTopicButtonEnabled(true);
  }, [
    cronExecutionInterval,
    frequency,
    parserName,
    selectedParser.id,
    setFusionSourceData,
    setIsSaveTopicButtonEnabled,
  ]);

  React.useEffect(() => {
    setCronExecution(
      selectedParser?.id !== createNewParser
        ? JSON.parse(selectedParser?.filter)
        : null,
    );
  }, [selectedParser, createNewParser]);

  const cronIntervalFormatter = (cron: string) => {
    if (cron) {
      const { unit, frequency } = covertCronToFrequencyAndUnit(
        cronExecution?.CronExecutionInterval,
      );
      return (
        <>
          {frequency} <span style={{ textTransform: 'lowercase' }}>{unit}</span>
        </>
      );
    }
  };

  return (
    <>
      {selectedParser?.id !== createNewParser ? (
        <Typography
          sx={{
            fontFamily: 'Rota',
            fontWeight: 700,
            fontSize: '16px',
            color: '#262949',
          }}
        >
          The selected parser runs every{' '}
          {cronIntervalFormatter(cronExecution?.CronExecutionInterval)}. You can
          edit the parser from a topic view.
        </Typography>
      ) : (
        <CronInput
          unit={unit}
          setUnit={setUnit}
          frequency={frequency}
          setFrequency={setFrequency}
        />
      )}
    </>
  );
};

export default CreateOffChainTopicStep2;
