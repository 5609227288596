import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { Dispatch, useEffect } from 'react';
import {
  eggshell,
  midnight,
  midnightDarker,
  midnightLight,
  white,
} from 'src/constants/colors';
import { MonthData } from './TopicsStatisticTable';
import { replaceUnderline } from 'src/util/stringUtils';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';

type Props = {
  selectedMonthList: string[];
  monthData: MonthData;
  setSelectedMonthList: Dispatch<React.SetStateAction<string[]>>;
};

export const TimeSelectDropdownMenuButton: React.FC<Props> = ({
  monthData,
  selectedMonthList,
  setSelectedMonthList,
}) => {
  const { isAP2Enabled } = useFeatureFlag();
  const handleChange = (e: SelectChangeEvent<typeof selectedMonthList>) => {
    const {
      target: { value },
    } = e;
    setSelectedMonthList(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    if (
      selectedMonthList.indexOf('All time') === 0 &&
      selectedMonthList.length > 1
    ) {
      setSelectedMonthList([...selectedMonthList.slice(1)]);
    }
    if (
      selectedMonthList.indexOf('All time') === selectedMonthList.length - 1 &&
      selectedMonthList.length > 1
    ) {
      setSelectedMonthList(['All time']);
    }
    if (selectedMonthList.length === 0) {
      setSelectedMonthList(['All time']);
    }
  }, [selectedMonthList, setSelectedMonthList]);

  return (
    <div>
      <FormControl
        sx={{
          textTransform: 'none',
          fontFamily: 'Rota',
          fontSize: '16px',
          fontWeight: 800,
          color: isAP2Enabled ? midnight : white,
          mr: 5,
          backgroundColor: isAP2Enabled ? eggshell : midnightDarker,
          width: '214px',
          height: '40px',
          justifyContent: 'space-between',
          borderRadius: '4px',
          '&:hover': {
            backgroundColor: isAP2Enabled ? eggshell : midnightDarker,
            border: 'none',
          },
        }}
      >
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          // NOTE: reneable this after proper topics mapping in implemented.
          disabled
          value={['All time']}
          onChange={(e) => handleChange(e)}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>All time</em>;
            }
            if (selected.length > 1) {
              return selected.slice(0, 2).join(', ') + '+' + selected.length;
            }
            return selected;
          }}
          MenuProps={{
            MenuListProps: {
              sx: {
                backgroundColor: isAP2Enabled ? white : midnightLight,
              },
            },
          }}
          // note, the second entry, iconComponent, hides the arrows
          inputProps={{
            'aria-label': 'Without label',
            IconComponent: () => null,
          }}
          sx={{
            '& fieldset': { borderColor: 'transparent' },
            height: '40px',
            color: isAP2Enabled ? midnight : null,
            '&:hover': {
              border: 'none',
            },
            ...(isAP2Enabled
              ? {
                  '&.Mui-disabled': {
                    color: '#2629495c',
                    '& div.Mui-disabled': {
                      WebkitTextFillColor: 'unset',
                    },
                  },
                }
              : null),
          }}
        >
          <MenuItem
            value="All time"
            sx={{
              fontFamily: 'Rota',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '17px',
              borderRadius: '4px',
              height: '33px',
              margin: '0 12px',
              p: 1,
            }}
          >
            <Checkbox checked={selectedMonthList.indexOf('All time') > -1} />
            <ListItemText
              primary="All time"
              sx={{
                ...(isAP2Enabled ? { '& > span': { color: midnight } } : null),
              }}
            />
          </MenuItem>
          {monthData.map((month, index) => (
            <MenuItem
              key={index}
              value={replaceUnderline(month.key)}
              sx={{
                fontFamily: 'Rota',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '17px',
                borderRadius: '4px',
                height: '33px',
                margin: '0 12px',
                p: 1,
              }}
            >
              <Checkbox
                checked={
                  selectedMonthList.indexOf(replaceUnderline(month.key)) > -1
                }
              />
              <ListItemText
                primary={replaceUnderline(month.key)}
                sx={{
                  ...(isAP2Enabled
                    ? { '& > span': { color: midnight } }
                    : null),
                }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
