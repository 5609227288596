import React, { useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useGetSubscriptionConfigData } from '@components/cardConfig/CardConfigDataContext';
import { dusk, midnight } from 'src/constants/colors';
import TopicsTab from './TopicsTab';
import { useGetFusionTopicsData } from '@components/alertManager/TopicsDataContext';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { CalloutTooltip } from '@components/alertManager/CalloutTooltip';
import CreateTopicModal from '@components/alertManager/CreateTopicModal';
import { PageHeaderSection } from '@components/section/Header';
import { useNavigate } from 'react-router-dom';
import { TABS } from '../AccountSettings/constants';
import ErrorsSection from './ErrorsSection';
import { FusionSourceState } from 'src/services/gql/generated';
import useClickOutside from 'src/hooks/useClickOutside';

export const AlertManagerTopicsViewContainer: React.FC = () => {
  const cnfigUICardRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [isCreateTopicModalOpen, setIsCreateTopicModalOpen] =
    React.useState<boolean>(false);
  const [isShowTooltip, setShowToolTip] = React.useState<boolean>(true);

  const { items: topicsData, isLoading: topicsDataIsLoading } =
    useGetFusionTopicsData();
  const { items: cardConfigData, isLoading: loadingSubscriptionConfigData } =
    useGetSubscriptionConfigData();
  useClickOutside(cnfigUICardRef, function () {
    setShowToolTip(false);
    localStorage.setItem('hasSeenConfigUICardTooltip', JSON.stringify(true));
  });

  const hasSeenConfigUICardTooltip = localStorage.getItem(
    'hasSeenConfigUICardTooltip',
  );

  const handleChange = (_: React.SyntheticEvent, value: number) => {
    switch (value) {
      case TABS.uiconfig.value:
        navigate('/alert/configs');
        localStorage.setItem(
          'hasSeenConfigUICardTooltip',
          JSON.stringify(true),
        );
        break;
      case TABS.storage.value:
        navigate('/alert/storage');
        break;
      default:
        navigate('/alert');
        break;
    }
  };

  const errorsParserList = useMemo(() => {
    if (!topicsData.length) return [];
    return topicsData?.filter(
      (topic) =>
        topic.fusionSource?.cursor?.sourceState === FusionSourceState.ERROR,
    );
  }, [topicsData]);

  if (topicsDataIsLoading || loadingSubscriptionConfigData)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: '200px',
          width: '100%',
        }}
      >
        <CircularProgress size="50px" thickness={2} />
      </Box>
    );

  return (
    <>
      <PageHeaderSection
        title="Alert Manager"
        subTitle="Welcome to the Notifi Network Admin Portal"
      />
      {topicsData && topicsData.length > 0 ? (
        <PrimaryButton
          buttonTitle="+ Topic"
          customSx={{
            height: '40px',
            padding: '10px',
            width: '100%',
            maxWidth: '90px',
            position: 'absolute',
            top: '55px',
            right: 0,
            zIndex: 10,
          }}
          onClick={() => {
            setIsCreateTopicModalOpen(true);
          }}
        />
      ) : null}
      <Box
        sx={{
          borderBottom: '1px solid #B6B8D5',
          mt: 2,
          position: 'relative',
        }}
      >
        {!hasSeenConfigUICardTooltip &&
        topicsData.length > 0 &&
        cardConfigData.length === 0 ? (
          <Box ref={cnfigUICardRef}>
            <CalloutTooltip
              title="Create a card so your users can subscribe"
              subtitle="Your topic was created. Next, configure your UI so that users can subscribe in your app. "
              buttonText="Configure UI Card"
              isTooltipOpen={isShowTooltip}
              placement={'bottom-start'}
              onClick={async () => {
                localStorage.setItem(
                  'hasSeenConfigUICardTooltip',
                  JSON.stringify(true),
                );
                navigate('/alert/configs');
              }}
            >
              <Tabs value={0} onChange={handleChange}>
                <Tab
                  disableRipple
                  label="Topics"
                  className=""
                  sx={{
                    '&.MuiButtonBase-root': {
                      padding: 0,
                      minWidth: '40px',
                      '&.Mui-selected': {
                        color: midnight,
                      },
                    },
                  }}
                />
                <Tab
                  disableRipple
                  label="UI Config"
                  sx={{
                    '&.MuiButtonBase-root': {
                      padding: 0,
                      ml: 4,
                      minWidth: '40px',
                      '&.Mui-selected': {
                        color: midnight,
                      },
                    },
                  }}
                />
                <Tab
                  disableRipple
                  label="Storage"
                  sx={{
                    '&.MuiButtonBase-root': {
                      padding: 0,
                      ml: 4,
                      minWidth: '40px',
                      '&.Mui-selected': {
                        color: midnight,
                      },
                    },
                  }}
                />
              </Tabs>
            </CalloutTooltip>
          </Box>
        ) : (
          <Tabs value={0} onChange={handleChange}>
            <Tab
              disableRipple
              label="Topics"
              className=""
              sx={{
                '&.MuiButtonBase-root': {
                  padding: 0,
                  minWidth: '40px',
                  '&.Mui-selected': {
                    color: midnight,
                  },
                },
              }}
            />
            <Tab
              disableRipple
              label="UI Config"
              sx={{
                '&.MuiButtonBase-root': {
                  padding: 0,
                  ml: 4,
                  minWidth: '40px',
                  '&.Mui-selected': {
                    color: midnight,
                  },
                },
              }}
            />
            <Tab
              disableRipple
              label="Storage"
              sx={{
                '&.MuiButtonBase-root': {
                  padding: 0,
                  ml: 4,
                  minWidth: '40px',
                  '&.Mui-selected': {
                    color: midnight,
                  },
                },
              }}
            />
          </Tabs>
        )}
      </Box>
      <Stack spacing={2} mt="40px">
        <>
          <CreateTopicModal
            isCreateTopicModalOpen={isCreateTopicModalOpen}
            setIsCreateTopicModalOpen={setIsCreateTopicModalOpen}
          />

          {errorsParserList.length > 0 && (
            <Stack>
              <Typography
                sx={{
                  mb: '12px',
                  fontSize: '22px',
                  fontWeight: 900,
                  color: dusk,
                }}
              >
                Errors
              </Typography>
              <ErrorsSection
                topics={topicsData}
                errorsParserList={errorsParserList}
              />
            </Stack>
          )}

          <Stack>
            {errorsParserList.length > 0 && (
              <Typography
                sx={{
                  mb: '12px',
                  fontSize: '22px',
                  fontWeight: 900,
                  color: dusk,
                }}
              >
                Topics
              </Typography>
            )}
            <Stack>
              <TopicsTab
                isLoading={topicsDataIsLoading}
                topicsData={topicsData}
                cardConfigData={cardConfigData}
              />
            </Stack>
          </Stack>
        </>
      </Stack>
    </>
  );
};
