import { Stack, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as IconGroup } from '../../assets/icn-group.svg';
import { dusk, light, midnight } from 'src/constants/colors';
import { useQuery } from '@apollo/client';
import { MessageDeliveryStatisticsQuery } from 'src/services/gql/queries/GetMessageDeliveryStatisticQuery.gql';

type GetMessageDeliveryStatisticsResult = {
  messageDeliveryStatistics: {
    messagesByTarget: {
      stats: {
        key: string;
        value: { messages: number; users: number };
      }[];
    };
  };
};

const SubscribeStatistic: React.FC = () => {
  const { data } = useQuery<GetMessageDeliveryStatisticsResult>(
    MessageDeliveryStatisticsQuery,
  );

  const totalSubscribedUsers =
    data?.messageDeliveryStatistics?.messagesByTarget?.stats?.find(
      (stat) => stat.key === 'Total',
    )?.value.users || 'N/A';

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{
        backgroundColor: `${light} !important`,
        flex: 1,
        borderRadius: '8px',
        p: '20px',
        minHeight: '323px',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
      }}
    >
      <IconGroup />
      <Stack sx={{ mt: '18px', fontFamily: 'Rota', textAlign: 'center' }}>
        <Typography
          sx={{
            color: midnight,
            fontSize: '30px',
            fontWeight: 900,
            lineHeight: 'normal',
          }}
        >
          {totalSubscribedUsers}
        </Typography>
        <Typography
          sx={{
            color: dusk,
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: 'normal',
            mt: '5px',
          }}
        >
          Subscribed Users
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SubscribeStatistic;
