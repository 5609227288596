import React from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { dusk, heather, midnight, white } from 'src/constants/colors';
import { FormMessageBar } from '@components/FormMessageBar';
import SummaryStatisticDashboard from '../../components/reporting/SummaryStatisticDashboard';
import TopicsStatisticTable from '../../components/reporting/TopicsStatisticTable';
import { useGetUserProfile } from '../AccountSettings/AccountSettingsDataContext';
import DetailedReportDownloadButton from '@components/reporting/DetailedReportDownloadButton';
import ReportingIcon from '../../assets/reporting-icon.svg';
import { useNavigate } from 'react-router-dom';
import SubscribeStatistic from '@components/reporting/SubscribeStatistic';
import DestinationsStatistic from '@components/reporting/DestinationsStatistic';
import {
  useGetMessageDeliveryStatisticsQuery,
  useGetUserTopicsQuery,
} from 'src/services/gql/generated';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import { useGetFusionCMTopicsData } from '@components/alertManager/TopicsDataContext';

export const ReportingView: React.FC = () => {
  const { isAP2Enabled, loaded } = useFeatureFlag();
  const { data } = useGetUserProfile();
  const { data: userTopicsData, loading: usersTopicsLoading } =
    useGetUserTopicsQuery();
  const { items: topicsData } = useGetFusionCMTopicsData();
  const navigate = useNavigate();
  const handleShowComposeView = () => {
    navigate('/community');
  };
  // This gets the subscriber data for the destinations statistics
  const { data: subscriberData } = useGetMessageDeliveryStatisticsQuery();

  const userTopicRows = isAP2Enabled
    ? topicsData
    : userTopicsData?.usersByTopic.usersByTopic ?? [];

  return (
    <>
      {loaded ? (
        <>
          {usersTopicsLoading ? (
            <Box
              sx={{
                minHeight: '600px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress size="100px" thickness={1} />
            </Box>
          ) : userTopicRows.length === 0 ||
            subscriberData?.messageDeliveryStatistics?.messagesByTarget?.stats
              ?.length === 0 ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ height: 'calc(100% - 300px)' }}
            >
              <FormMessageBar />
              <Stack
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ width: '350px', textAlign: 'center' }}
              >
                <img
                  src={ReportingIcon}
                  style={{
                    paddingBottom: '18px',
                    width: '40px',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '30px',
                    fontWeight: 900,
                    color: isAP2Enabled ? midnight : white,
                    pb: 2,
                  }}
                >
                  Reporting
                </Typography>
                <Typography sx={{ color: isAP2Enabled ? dusk : white, mb: 3 }}>
                  Reporting will be available when you have live topics and
                  subscriber data.
                </Typography>
                <PrimaryButton
                  buttonTitle="Alert Manager"
                  onClick={() => navigate('/alert')}
                />
              </Stack>
            </Stack>
          ) : (
            <Stack sx={{ position: 'relative' }}>
              <Stack
                sx={
                  isAP2Enabled
                    ? {
                        background: '#1C1E3A',
                        padding: '48px 5%',
                        height: '216px',
                      }
                    : { padding: '48px 5%' }
                }
              >
                <FormMessageBar />
                <Stack
                  flexDirection="row"
                  alignItems="baseline"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      color: white,
                      fontFamily: 'Rota',
                      fontSize: '30px',
                      fontWeight: 900,
                    }}
                  >
                    Reporting
                    <Typography
                      component="span"
                      sx={{
                        display: 'block',
                        color: heather,
                        fontSize: '18px',
                        fontWeight: 700,
                        mt: '12px',
                      }}
                    >
                      {data?.userProfile?.tenantInfo?.name ?? ''}
                    </Typography>
                  </Typography>
                  <DetailedReportDownloadButton />
                </Stack>
              </Stack>
              <Stack marginX="5%">
                {isAP2Enabled ? (
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      gap: '36px',
                      mb: '36px',
                      mt: '-60px',
                    }}
                  >
                    <SubscribeStatistic />
                    <DestinationsStatistic />
                  </Stack>
                ) : (
                  <SummaryStatisticDashboard />
                )}
                <TopicsStatisticTable
                  handleShowComposeView={handleShowComposeView}
                />
              </Stack>
            </Stack>
          )}
        </>
      ) : null}
    </>
  );
};
