export type NotifiEnvironment = 'dev' | 'prd';
const mgwApiUrlOverride = localStorage.getItem('config-tool:MGW_API_URL_OVERRIDE') || '';
const dpgwApiUrlOverride = localStorage.getItem('config-tool:DPGW_API_URL_OVERRIDE') || '';

export const getApiUrl = (env: NotifiEnvironment): string => {
  if (mgwApiUrlOverride) {
    return mgwApiUrlOverride + '/gql';
  }

  switch (env) {
    case 'dev':
      return 'https://api.dev.notifi.network/gql';
    case 'prd':
      return 'https://api.notifi.network/gql';
  }
};

export const fromStringOrNull = (value: string | null): NotifiEnvironment => {
  switch (value) {
    case 'dev':
    case 'prd':
      return value;
    default:
      return 'prd';
  }
};

export const getRecaptchaSiteKey = (env: NotifiEnvironment): string => {
  switch (env) {
    case 'dev':
      return '6LdreKQmAAAAACprjZMawaMbOnLDC0PDvfULEmV6';
    case 'prd':
      return '6LcBhKQmAAAAAE-G_lv-yeliu72JPT6iUvcXdFi_';
  }
};

export const generateDataplaneGatewayApiUrl = (
  env: NotifiEnvironment,
): string => {
  if (dpgwApiUrlOverride) {
    return dpgwApiUrlOverride + '/FusionIngest/';
  }

  const baseUrl = 'notifi.network/FusionIngest/';
  return `https://dpapi.${env}.${baseUrl}`;
};
