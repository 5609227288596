import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { SecondaryButton } from '@components/buttons/SecondaryButton';
import { Stack } from '@mui/material';
import React from 'react';
import { ReactComponent as CheckIcon } from '../../assets/icn-check-white.svg';
import {
  blueglass,
  midnight,
  seaglassDarker,
  white,
} from 'src/constants/colors';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import { DestinationsType } from 'src/pages/Community/CreateFusionMessageForm';

type DestinationSelectButtonsProps = Readonly<{
  emailSelected: boolean;
  SMSSelected: boolean;
  telegramSelected: boolean;
  discordSelected: boolean;
  slackSelected?: boolean;
  walletAlertSelected?: boolean;
  setEmailSelected: (emailSelected: boolean) => void;
  setSMSSelected: (SMSSelected: boolean) => void;
  setTelegramSelected: (telegramSelected: boolean) => void;
  setDiscordSelected: (discordSelected: boolean) => void;
  setAlertHistorySelected: (AlertHistorySelected: boolean) => void;
  setSlackSelected?: (slackSelected: boolean) => void;
  setWalletAlertSelected?: (slackSelected: boolean) => void;
}>;

type ButtonsProps = {
  destination: DestinationsType;
  selected?: boolean;
  setSelected?: (v: boolean) => void;
  disabled: boolean;
};

export const DestinationSelectButtons: React.FC<
  DestinationSelectButtonsProps
> = ({
  emailSelected,
  SMSSelected,
  telegramSelected,
  discordSelected,
  slackSelected,
  walletAlertSelected,
  setEmailSelected,
  setSMSSelected,
  setTelegramSelected,
  setDiscordSelected,
  setAlertHistorySelected,
  setSlackSelected,
  setWalletAlertSelected,
}) => {
  const { isAP2Enabled } = useFeatureFlag();
  const buttons: ButtonsProps[] = [
    {
      destination: 'Alert History',
      selected: true,
      setSelected: setAlertHistorySelected,
      disabled: false,
    },
    {
      destination: 'Email',
      selected: emailSelected,
      setSelected: setEmailSelected,
      disabled: false,
    },
    {
      destination: 'SMS',
      selected: SMSSelected,
      setSelected: setSMSSelected,
      disabled: false,
    },
    {
      destination: 'Telegram',
      selected: telegramSelected,
      setSelected: setTelegramSelected,
      disabled: false,
    },
    {
      destination: 'Discord',
      selected: discordSelected,
      setSelected: setDiscordSelected,
      disabled: false,
    },
    {
      destination: 'Slack',
      selected: slackSelected,
      setSelected: setSlackSelected,
      disabled: false,
    },
    {
      destination: 'Wallet',
      selected: walletAlertSelected,
      setSelected: setWalletAlertSelected,
      disabled: false,
    },
  ];
  return (
    <Stack
      flexDirection="row"
      justifyContent="start"
      alignItems="center"
      flexWrap="wrap"
    >
      {buttons.map((button, index) => {
        const { destination, setSelected } = button;
        const isAP2Destination =
          destination === 'Slack' || destination === 'Wallet';

        return (
          <React.Fragment key={index}>
            {!isAP2Enabled && isAP2Destination ? null : button.selected ? (
              <PrimaryButton
                startIcon={<CheckIcon />}
                customSx={{
                  boxShadow: 'none',
                  color: white,
                  height: '40px',
                  width: '140px',
                  mr: 3,
                  mb: 3,
                  background: blueglass,
                  '&:hover': {
                    background: seaglassDarker,
                  },
                }}
                buttonTitle={destination}
                onClick={() => {
                  if (destination === 'Alert History') {
                    setSelected ? setSelected(true) : null;
                  } else {
                    setSelected ? setSelected(false) : null;
                  }
                }}
              />
            ) : (
              <SecondaryButton
                disabled={button.disabled}
                buttonTitle={`+ ${button.destination}`}
                onClick={() => (setSelected ? setSelected(true) : null)}
                customSx={{
                  height: '40px',
                  mb: 3,
                  ...(isAP2Enabled
                    ? { color: midnight, borderColor: midnight }
                    : null),
                  width: '140px',
                  mr: 3,
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

export default DestinationSelectButtons;
