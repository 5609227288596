import React, { useState } from 'react';
import {
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { dusk, midnight, seaglassDarker, white } from 'src/constants/colors';
import { TimeSelectDropdownMenuButton } from './TimeSelectDropdownMenuButton';
import {
  useTenantMessagesByMonthQuery,
  useTenantMessagesByTopicQuery
} from 'src/services/gql/generated';
import { replaceUnderline } from 'src/util/stringUtils';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';

export type MonthData = {
  __typename?: 'KeyValuePairOfStringAndInt32' | undefined;
  key: string;
  value: number;
}[];

// Courtesy of @Chad on StackOverflow: https://stackoverflow.com/a/2706169/2033557
function addMonths(date: Date, months: number) {
  const d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() != d) {
    date.setDate(0);
  }
  return date;
}

type TopicsStatisticTableProps = {
  handleShowComposeView: () => void;
};

const TopicsStatisticTable: React.FC<TopicsStatisticTableProps> = ({
  handleShowComposeView,
}) => {
  const { isAP2Enabled } = useFeatureFlag();
  const [selectedMonthList, setSelectedMonthList] = useState<string[]>([
    'All time',
  ]);

  const { data: messageByMonthData } = useTenantMessagesByMonthQuery();

  const messagesByMonth: MonthData =
    messageByMonthData?.totalMessagesPerMonthByTenant?.messagesPerMonth ?? [];

  let filteredMessagesByMonth: MonthData = messagesByMonth;

  // TODO: Include paging
  let messageByTopicVariables: { startDate?: string, endDate?: string };
  if (!selectedMonthList.includes('All time')) {
    filteredMessagesByMonth = messagesByMonth.filter((month) =>
      selectedMonthList.includes(replaceUnderline(month.key)),
    );
    // TODO: Restrict the month filters to a contiguous range, perhaps just one month.
    // Right now, if you pick non-contiguous months, the messages by topic filter
    // will include all the months between them.
    let minDate = Number.MAX_VALUE;
    let maxDate = Number.MIN_VALUE;
    for (const selectedMonth of selectedMonthList) {
      const monthStart = Date.parse(selectedMonth);
      const monthEnd = addMonths(new Date(monthStart), 1).getTime();
      minDate = Math.min(minDate, monthStart);
      maxDate = Math.max(maxDate, monthEnd);
    }
    messageByTopicVariables = {
      startDate: new Date(minDate).toISOString(),
      endDate: new Date(maxDate).toISOString()
    }
  }
  else {
    messageByTopicVariables = {};
  }
  const filteredTotalMessageCount = filteredMessagesByMonth.reduce(
    (messageCount, month) => (messageCount += month?.value),
    0,
  );

  const { data: messageByTopicData, loading: messageByTopicLoading } = useTenantMessagesByTopicQuery({
    variables: messageByTopicVariables
  })
  const messageByTopicRows = messageByTopicData?.totalMessagesPerTopicByTenant?.nodes ?? [];

  return (
    <TableContainer
      component={Paper}
      sx={{
        mb: 3,
        fontFamily: 'Rota',
      }}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: isAP2Enabled ? white : midnight,
          pt: 3,
        }}
      >
        <Typography
          sx={{
            fontSize: '22px',
            fontWeight: 900,
            pl: 6,
            color: isAP2Enabled ? midnight : white,
          }}
        >
          Messages sent: {filteredTotalMessageCount}
        </Typography>
        <TimeSelectDropdownMenuButton
          monthData={messagesByMonth}
          setSelectedMonthList={setSelectedMonthList}
          selectedMonthList={selectedMonthList}
        />
      </Stack>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        {messageByTopicLoading ? (
          <TableBody
            sx={{
              minHeight: '600px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress size="100px" thickness={1} />
          </TableBody>
        ) : messageByTopicRows.length > 0 ? (
          <>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: isAP2Enabled ? white : midnight,
                  '& .MuiTableCell-root': {
                    fontSize: 14,
                    fontWeight: 700,
                  },
                }}
              >
                <TableCell
                  sx={{
                    fontFamily: 'Rota',
                    color: dusk,
                    fontSize: '14px',
                    fontWeight: 700,
                    pl: 6,
                    width: 'min(max(150px, 30vw), 250px)',
                    ...(isAP2Enabled ? { pb: '5px' } : null),
                  }}
                >
                  Topics
                </TableCell>
                <TableCell
                  sx={isAP2Enabled ? { width: '200px' } : { width: '100px' }}
                ></TableCell>
                <TableCell
                  align={isAP2Enabled ? 'left' : 'center'}
                  sx={{
                    fontFamily: 'Rota',
                    color: dusk,
                    fontSize: '14px',
                    fontWeight: 700,
                    ...(isAP2Enabled ? { pb: '5px' } : { width: 'auto' }),
                  }}
                >
                  Subscribed Users
                </TableCell>
                {/* NOTE: Messages sent hidden for now while being reworked */}
                {isAP2Enabled ? (
                  <TableCell
                    align="left"
                    sx={{
                      fontFamily: 'Rota',
                      color: dusk,
                      fontSize: '14px',
                      fontWeight: 700,
                      pb: '5px',
                    }}
                  >
                    Messages Sent
                  </TableCell>
                ) : null}
                <TableCell
                  sx={isAP2Enabled ? { width: '150px' } : { width: '200px' }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ background: isAP2Enabled ? white : midnight }}>
              {messageByTopicRows.map((row) => (
                <TableRow
                  key={row.eventTypeId}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    fontFamily: 'Rota',
                    fontSize: '16px',
                    fontWeight: 600,
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      pl: 6,
                      width: 'min(max(150px, 30vw), 250px)',
                      fontFamily: 'Rota',
                      fontSize: '16px',
                      fontWeight: 600,
                      ...(isAP2Enabled
                        ? {
                            color: midnight,
                            textTransform: 'capitalize',
                          }
                        : null),
                    }}
                  >
                    {/* Topic*/}
                    {row.topicName}
                  </TableCell>
                  <TableCell
                    sx={isAP2Enabled ? { width: '200px' } : { width: '100px' }}
                  ></TableCell>
                  <TableCell
                    align={isAP2Enabled ? 'left' : 'center'}
                    sx={{
                      fontFamily: 'Rota',
                      fontSize: '16px',
                      fontWeight: 600,
                      ...(isAP2Enabled
                        ? { color: midnight }
                        : { width: 'auto' }),
                    }}
                  >
                    {/* Subscribed Users */}
                    {row.subscriberCount}
                  </TableCell>
                  {/* Messages sent.
                   NOTE: This is hidden for now */}
                  {isAP2Enabled ? (
                    <>
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: 'Rota',
                          fontSize: '16px',
                          fontWeight: 600,
                          color: midnight,
                          backgroundColor: white,
                        }}
                      >
                        {row.messageCount}
                      </TableCell>
                      <TableCell sx={{ width: '150px' }}></TableCell>
                    </>
                  ) : (
                    <TableCell
                      align="left"
                      sx={{
                        width: '200px',
                        fontFamily: 'Rota',
                        fontSize: '14px',
                        fontWeight: 700,
                        color: seaglassDarker,
                      }}
                    >
                      <span
                        onClick={handleShowComposeView}
                        style={{ cursor: 'pointer' }}
                      >
                        Compose
                      </span>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </>
        ) : (
          <>
            <TableBody
              sx={{
                minHeight: '500px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: isAP2Enabled ? midnight : white,
                backgroundColor: isAP2Enabled ? white : midnight,
              }}
            >
              No topics found
            </TableBody>
          </>
        )}
      </Table>
    </TableContainer>
  );
};

export default TopicsStatisticTable;
