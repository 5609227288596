import React, { createContext, PropsWithChildren, useContext } from 'react';
import { useTenantFeatureFlagQuery } from 'src/services/gql/generated';

export type FeatureFlagState = Readonly<{
  isAP2Enabled: boolean;
  isFlagVersion19: boolean;
  isFlagVersion195: boolean;
  loaded: boolean;
  isMultilevelTokenFlowForEmailEnabled: boolean
  canRequire2FA: boolean
  canFilterOnTag: boolean
}>;

const FeatureFlagContext = createContext<FeatureFlagState>({
  isAP2Enabled: false,
  isFlagVersion19: false,
  isFlagVersion195: false,
  loaded: false,
  isMultilevelTokenFlowForEmailEnabled:false,
  canRequire2FA: false,
  canFilterOnTag: false
});

function parseFeatureFlagToBoolean(value: string | undefined) {
  if (value === 'false') return false;
  return !!value;
}

export const FeatureFlagProvider: React.FC<
  PropsWithChildren<Record<string, unknown>>
> = ({ children }) => {
  const { data, loading } = useTenantFeatureFlagQuery({
    variables: {
      TenantFeatureFlagInput: { tenantFeatureFlagName: 'AdminPanel' },
    },
  });

  const { data : multilevelTokenFlowForEmailEnabledData } = useTenantFeatureFlagQuery({
    variables: {
      TenantFeatureFlagInput: { tenantFeatureFlagName: 'multilevelTokenFlowForEmail' },
    },
  });

  const { data : canRequire2FAData } = useTenantFeatureFlagQuery({
    variables: {
      TenantFeatureFlagInput: { tenantFeatureFlagName: 'canRequire2FA' },
    },
  });

  const { data : canFilterOnTagData } = useTenantFeatureFlagQuery({
    variables: {
      TenantFeatureFlagInput: { tenantFeatureFlagName: 'canFilterOnTag' },
    },
  })

  let isAP2Enabled = false;
  let isFlagVersion19 = false;
  let isFlagVersion195 = true;
  if (data?.tenantFeatureFlag?.value) {
    const featureFlagValue = JSON.parse(data?.tenantFeatureFlag?.value ?? '');
    isAP2Enabled =
      featureFlagValue.version === '2.0' ||
      featureFlagValue.version === '1.9' ||
      featureFlagValue.version === '1.95';
    isFlagVersion19 = featureFlagValue.version === '1.9';
    isFlagVersion195 = featureFlagValue.version === '1.95';
  }
  const loaded = !loading;
  const isMultilevelTokenFlowForEmailEnabled = parseFeatureFlagToBoolean(multilevelTokenFlowForEmailEnabledData?.tenantFeatureFlag.value)
  const canRequire2FA = parseFeatureFlagToBoolean(canRequire2FAData?.tenantFeatureFlag.value)
  const canFilterOnTag = parseFeatureFlagToBoolean(canFilterOnTagData?.tenantFeatureFlag.value)

  return (
    <FeatureFlagContext.Provider
      value={{
        isAP2Enabled,
        isFlagVersion19,
        isFlagVersion195,
        loaded,
        isMultilevelTokenFlowForEmailEnabled,
        canRequire2FA,
        canFilterOnTag
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlag = (): FeatureFlagState => {
  return useContext(FeatureFlagContext);
};
