import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as IconDiscord } from '../../../assets/icn-discord.svg';
import { ReactComponent as IconNotifi } from '../../../assets/icn-notifi.svg';
import { ReactComponent as IconEmail } from '../../../assets/icn-email.svg';
import { ReactComponent as IconTelegram } from '../../../assets/icn-telegram.svg';
import { ReactComponent as IconSms } from '../../../assets/icn-sms.svg';
import { eggshell, heather } from 'src/constants/colors';
import { ReactComponent as CheckIcon } from '../../../assets/icn-check-seaglass.svg';
import { TemplateType } from 'src/services/gql/generated';
import { ReactComponent as SlackIcon } from '../../../assets/icn-slack.svg';
import { ReactComponent as WalletIcon } from '../../../assets/icn-wallet.svg';

type Props = {
  setIsDefineTemplatesDialogOpen: (value: boolean) => void;
  uploadedTemplateTypes: TemplateType[] | undefined;
};

const TemplatesCard = ({
  setIsDefineTemplatesDialogOpen,
  uploadedTemplateTypes,
}: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: '#FEFEFE',
        padding: '24px 30px 24px 30px',
        borderRadius: '8px',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%', mb: '12px' }}
      >
        <Typography
          sx={{
            fontSize: '22px',
            fontWeight: 900,
            mb: '8px',
            mt: '16px',
          }}
        >
          Templates
        </Typography>

        <PrimaryButton
          onClick={() => setIsDefineTemplatesDialogOpen(true)}
          buttonTitle="Edit"
          customSx={{ width: '67px', height: '28px', fontSize: '14px' }}
        />
      </Stack>
      <Box
        sx={{
          width: '100%',
          backgroundColor: eggshell,
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'row',
          p: '8px 16px',
          mt: '8px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack flexDirection="row">
          <IconNotifi
            style={{
              fill: heather,
              width: 15,
              height: 15,
              marginRight: '12px',
            }}
          />
          <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
            Alert History
          </Typography>
        </Stack>
        {uploadedTemplateTypes &&
        uploadedTemplateTypes.includes(TemplateType.EVENT_DETAIL_BODY) ? (
          <CheckIcon />
        ) : null}
      </Box>
      <Box
        sx={{
          width: '100%',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'row',
          p: '8px 16px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack flexDirection="row">
          <IconEmail
            style={{
              fill: heather,
              width: 15,
              height: 15,
              marginRight: '12px',
            }}
          />
          <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
            Email
          </Typography>
        </Stack>
        {uploadedTemplateTypes &&
        uploadedTemplateTypes.includes(TemplateType.EMAIL) ? (
          <CheckIcon />
        ) : null}
      </Box>
      <Box
        sx={{
          width: '100%',
          backgroundColor: eggshell,
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'row',
          p: '8px 16px',
          mb: '8px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack flexDirection="row">
          <IconTelegram
            style={{
              fill: heather,
              width: 15,
              height: 15,
              marginRight: '12px',
            }}
          />
          <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
            Telegram
          </Typography>
        </Stack>
        {uploadedTemplateTypes &&
        uploadedTemplateTypes.includes(TemplateType.TELEGRAM) ? (
          <CheckIcon />
        ) : null}
      </Box>
      <Box
        sx={{
          width: '100%',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'row',
          p: '8px 16px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack flexDirection="row">
          <IconSms
            style={{
              fill: heather,
              width: 15,
              height: 15,
              marginRight: '12px',
            }}
          />
          <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
            SMS
          </Typography>
        </Stack>
        {uploadedTemplateTypes &&
        uploadedTemplateTypes.includes(TemplateType.SMS) ? (
          <CheckIcon />
        ) : null}
      </Box>
      <Box
        sx={{
          width: '100%',
          backgroundColor: eggshell,
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'row',
          p: '8px 16px',
          mb: '8px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack flexDirection="row">
          <IconDiscord
            style={{
              fill: heather,
              width: 15,
              height: 15,
              marginRight: '12px',
            }}
          />
          <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
            Discord
          </Typography>
        </Stack>
        {uploadedTemplateTypes &&
        uploadedTemplateTypes.includes(TemplateType.DISCORD) ? (
          <CheckIcon />
        ) : null}
      </Box>
      <Box
        sx={{
          width: '100%',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'row',
          p: '8px 16px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack flexDirection="row">
          <SlackIcon
            style={{
              fill: heather,
              width: 15,
              height: 15,
              marginRight: '12px',
            }}
          />
          <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
            Slack
          </Typography>
        </Stack>
        {uploadedTemplateTypes &&
        uploadedTemplateTypes.includes(TemplateType.SLACK_CHANNEL) ? (
          <CheckIcon />
        ) : null}
      </Box>
      <Box
        sx={{
          width: '100%',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'row',
          p: '8px 16px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack flexDirection="row">
          <WalletIcon
            style={{
              fill: heather,
              width: 20,
              height: 20,
              marginRight: '8px',
            }}
          />
          <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
            Wallet
          </Typography>
        </Stack>
        {uploadedTemplateTypes &&
        uploadedTemplateTypes.includes(TemplateType.WEB3) ? (
          <CheckIcon />
        ) : null}
      </Box>
    </Box>
  );
};

export default TemplatesCard;
