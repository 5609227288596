import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';

import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import TurndownService from 'turndown';
import { v4 as uuidv4 } from 'uuid';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import { ConfirmDialog } from '@components/cardConfig/ConfirmDialog';
import { ReactComponent as CheckIcon } from '../../assets/icn-check-seaglass.svg';
import {
  cloud,
  dusk,
  eggshell,
  heather,
  light,
  midnight,
  midnightDarker,
  midnightLight,
  white,
} from 'src/constants/colors';
import { useTopics } from './useTopics';
import useMessageService from './MessageService';
import { sanitizeHTML } from 'src/util/stringUtils';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import DestinationSelectButtons from '@components/community/DestinationSelectButtons';
import DiscordComposer from '@components/community/DiscordComposer';
import { TelegramComposer } from '@components/community/TelegramComposer';
import { SMSComposer } from '@components/community/SMSComposer';
import { EmailComposer } from '@components/community/EmailComposer';
import {
  BatchBroadcastMessageInput,
  TargetType,
} from 'src/services/gql/generated';
import { AlertHistoryComposer } from '@components/community/AlertHistoryComposer';
import { ReactComponent as IconChevronDown } from '../../assets/icn-chevron-down.svg';
import { notNullOrUndefined } from '@components/auth/AuthContext';
import { NotifiTooltip } from '@components/NotifiTooltip';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';

type MessageProps = {
  contentPlaceholder?: string;
  errorMessage?: string;
  idempotencyKey?: string;
};

export type OptionsType = {
  emDelimiter: '*' | '_' | undefined;
  strongDelimiter: '**' | '__' | undefined;
};

export const isEmptyContent = (content: string): boolean =>
  content === '' || content === '<p><br></p>';

const CreateMessageForm: React.FC<MessageProps> = ({ idempotencyKey }) => {
  const { isAP2Enabled } = useFeatureFlag();
  const { createBroadcastMessage } = useMessageService();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [selectedTopicKey, setSelectedTopicKey] = useState<string>('');
  const [selectedTopicName, setSelectedTopicName] = useState<string>('');
  const [emailMessageContent, setEmailMessageContent] = useState('');
  const [SMSMessageContent, setSMSMessageContent] = useState('');
  const [telegramMessageContent, setTelegramMessageContent] =
    useState<string>('');
  const [discordMessageContent, setDiscordMessageContent] =
    useState<string>('');
  const [alertHistoryMessageContent, setAlertHistoryMessageContent] =
    useState<string>('');
  const [subject, setSubject] = useState('');
  const [alertHistorySubject, setAlertHistorySubject] = useState('');
  const [emailSelected, setEmailSelected] = useState<boolean>(false);
  const [SMSSelected, setSMSSelected] = useState<boolean>(false);
  const [telegramSelected, setTelegramSelected] = useState<boolean>(false);
  const [discordSelected, setDiscordSelected] = useState<boolean>(false);
  const [alertHistorySelected, setAlertHistorySelected] =
    useState<boolean>(true);
  const [selectedFormat, setSelectedFormat] = useState<string>(
    'Basic Notifi Template',
  );
  const [customHTML, setCustomHTML] = useState<string>('');
  const [unstyledMessage, setUnstyledMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [campaignId, setCampaignId] = useState<string>('');
  const topics = useTopics();
  const { setMessageBarState, clearMessageBarState } =
    useFormMessageBarContext();
  const setErrorMessage = (value: string) => {
    setMessageBarState({ status: 'error', message: value });
  };

  const options: OptionsType = {
    emDelimiter: '*',
    strongDelimiter: '**',
  };
  const turndownService = new TurndownService(options);

  useEffect(() => {
    const keys = Object.keys(topics);
    if (selectedTopicKey === '') {
      if (keys.length > 0) {
        setSelectedTopicName('Select Topic');
      }
    } else {
      if (keys.length > 0) {
        setSelectedTopicName(topics[selectedTopicKey].name);
      }
    }
  }, [selectedTopicKey, topics]);

  const hasValues = () => {
    const hasInValidEmailContent = () => {
      if (selectedFormat === 'Basic Notifi Template') {
        return (
          emailSelected &&
          (isEmptyContent(emailMessageContent) || subject === '')
        );
      } else if (selectedFormat === 'Custom HTML') {
        return (
          emailSelected &&
          (customHTML === '' || unstyledMessage === '' || subject === '')
        );
      }
    };
    const hasInvalidSmsContent =
      SMSSelected && isEmptyContent(SMSMessageContent);
    const hasInvalidTelegramContent =
      telegramSelected && isEmptyContent(telegramMessageContent);
    const hasInvalidDiscordContent =
      discordSelected && isEmptyContent(discordMessageContent);
    const hasInvalidAlertHistoryContent =
      alertHistorySelected &&
      (isEmptyContent(alertHistoryMessageContent) ||
        alertHistorySubject === '');
    if (
      hasInValidEmailContent() ||
      hasInvalidSmsContent ||
      hasInvalidTelegramContent ||
      hasInvalidDiscordContent ||
      hasInvalidAlertHistoryContent
    ) {
      return false;
    } else if (
      !emailSelected &&
      !SMSSelected &&
      !telegramSelected &&
      !discordSelected &&
      !alertHistorySelected
    ) {
      return false;
    }
    return true;
  };

  const isEnabled = selectedTopicKey !== '' && campaignId !== '' && hasValues();

  const clearFields = () => {
    setEmailMessageContent('');
    setSMSMessageContent('');
    setTelegramMessageContent('');
    setDiscordMessageContent('');
    setSubject('');
    setSelectedTopicKey('');
    setCustomHTML('');
    setUnstyledMessage('');
    setAlertHistoryMessageContent('');
    setAlertHistorySubject('');
    setCampaignId('');
  };

  const handlePublish = async () => {
    setLoading(true);
    const messageType = 'ANNOUNCEMENT';

    const topic = topics[selectedTopicKey];
    if (topic === undefined) {
      throw new Error('Please select a topic');
    }

    let sanitizedEmailValues = { subject: '', message: '' };
    let sanitizedCustomEmailValues = {
      subject: '',
      htmlMessage: '',
      unstyledMessage: '',
    };
    let sanitizedSMSMessage = '';
    let sanitizedTelegramMessage = '';
    let sanitizedDiscordMessage = '';
    let sanitizedAlertHistoryValues = { subject: '', message: '' };
    const variables: BatchBroadcastMessageInput['targetDestinationVariables'] =
      [];
    const targetTemplate = notNullOrUndefined(topic.targetTemplate)
      ? topic.targetTemplate
      : '';

    if (emailSelected) {
      if (selectedFormat === 'Basic Notifi Template') {
        sanitizedEmailValues = {
          message: sanitizeHTML(emailMessageContent),
          subject: sanitizeHTML(subject),
        };
        variables.push({
          key: TargetType.EMAIL,
          value: [
            { key: 'type', value: messageType },
            { key: 'subject', value: sanitizedEmailValues.subject },
            {
              key: 'message__markdown',
              value: turndownService.turndown(sanitizedEmailValues.message),
            },
            { key: 'notifiCampaignId', value: campaignId },
            { key: 'targetTemplate', value: targetTemplate },
          ],
        });
      } else if (selectedFormat === 'Custom HTML') {
        sanitizedCustomEmailValues = {
          htmlMessage: customHTML,
          unstyledMessage: sanitizeHTML(unstyledMessage),
          subject: sanitizeHTML(subject),
        };
        variables.push({
          key: TargetType.EMAIL,
          value: [
            { key: 'type', value: messageType },
            { key: 'subject', value: sanitizedCustomEmailValues.subject },
            {
              key: 'customHtmlPayload',
              value: sanitizedCustomEmailValues.htmlMessage,
            },
            {
              key: 'unstyledMessage',
              value: sanitizedCustomEmailValues.unstyledMessage,
            },
            { key: 'notifiCampaignId', value: campaignId },
            { key: 'targetTemplate', value: targetTemplate },
          ],
        });
      }
    }
    if (SMSSelected) {
      sanitizedSMSMessage = sanitizeHTML(SMSMessageContent);
      variables.push({
        key: TargetType.SMS,
        value: [
          { key: 'type', value: messageType },
          {
            key: 'message__markdown',
            value: turndownService.turndown(sanitizedSMSMessage),
          },
          { key: 'notifiCampaignId', value: campaignId },
          { key: 'targetTemplate', value: targetTemplate },
        ],
      });
    }
    if (telegramSelected) {
      sanitizedTelegramMessage = sanitizeHTML(telegramMessageContent);
      variables.push({
        key: TargetType.TELEGRAM,
        value: [
          { key: 'type', value: messageType },
          {
            key: 'message__markdown',
            value: turndownService.turndown(sanitizedTelegramMessage),
          },
          { key: 'notifiCampaignId', value: campaignId },
          {
            key: 'targetTemplate',
            value: targetTemplate,
          },
        ],
      });
    }
    if (discordSelected) {
      sanitizedDiscordMessage = sanitizeHTML(discordMessageContent);
      variables.push({
        key: TargetType.DISCORD,
        value: [
          { key: 'type', value: messageType },
          {
            key: 'message__markdown',
            value: turndownService.turndown(sanitizedDiscordMessage),
          },
          { key: 'notifiCampaignId', value: campaignId },
          { key: 'targetTemplate', value: targetTemplate },
        ],
      });
    }
    if (alertHistorySelected) {
      sanitizedAlertHistoryValues = {
        message: sanitizeHTML(alertHistoryMessageContent),
        subject: sanitizeHTML(alertHistorySubject),
      };
      variables.push({
        key: TargetType.PLATFORM,
        value: [
          { key: 'type', value: messageType },
          { key: 'subject', value: sanitizedAlertHistoryValues.subject },
          {
            key: 'message__markdown',
            value: turndownService.turndown(
              sanitizedAlertHistoryValues.message,
            ),
          },
          { key: 'notifiCampaignId', value: campaignId },
          { key: 'targetTemplate', value: targetTemplate },
        ],
      });
    }
    await createMessage({
      topic: topic.topicName,
      variables,
    });
  };

  const createMessage = async ({
    topic,
    variables,
  }: {
    topic: string;
    variables: BatchBroadcastMessageInput['targetDestinationVariables'];
  }) => {
    let batchBroadcastMessageInput: BatchBroadcastMessageInput = {
      idempotencyKey: '',
      topicName: '',
      targetDestinationVariables: [],
    };
    batchBroadcastMessageInput = {
      idempotencyKey: idempotencyKey ?? uuidv4(),
      topicName: topic,
      targetDestinationVariables: variables,
    };

    try {
      createBroadcastMessage(batchBroadcastMessageInput, {
        onError: () => {
          setErrorMessage('Failed to send message. 🖊️');
        },
        onSuccess: () => {
          setIsSuccessModalOpen(true);

          setLoading(false);
          clearFields();
          clearMessageBarState();
        },
      });
    } catch (err) {
      setErrorMessage('Failed to send message. 🖊️');
    }
  };

  return (
    <Stack>
      <Stack
        sx={{
          '& fieldset': { border: 'none' },
          backgroundColor: isAP2Enabled ? light : midnight,
          borderRadius: '8px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
          flexGrow: 1,
          mb: 2,
          minWidth: '700px',
          px: 5,
          py: 1,
        }}
      >
        <FormControl sx={{ mb: 3, mt: 2, width: '100%' }}>
          <Typography
            sx={{
              color: isAP2Enabled ? '#80829D' : dusk,
              fontFamily: 'Rota',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
            }}
          >
            Audience / Notification Topic
          </Typography>
          <Select
            displayEmpty
            labelId="topic-input-label"
            IconComponent={(props) => {
              return (
                <IconChevronDown
                  {...props}
                  style={{
                    marginRight: '12px',
                    top: 'calc(50% - .7em)',
                    fill: isAP2Enabled ? dusk : white,
                  }}
                />
              );
            }}
            inputProps={{
              MenuProps: {
                MenuListProps: {
                  sx: {
                    backgroundColor: isAP2Enabled ? white : midnightLight,
                  },
                },
              },
            }}
            onChange={(e) => {
              setSelectedTopicKey(e.target.value ?? '');
              clearMessageBarState();
            }}
            renderValue={(value) => {
              if (value === 'Select Topic') {
                return (
                  <Typography sx={{ color: dusk, fontFamily: 'RotaSemiBold' }}>
                    {value}
                  </Typography>
                );
              }
              return value;
            }}
            size="small"
            sx={{
              fontFamily: 'Rota',
              fontWeight: 600,
              fontSize: '16x',
              ...(isAP2Enabled
                ? {
                    backgroundColor: eggshell,
                    color: midnight,
                    '& .MuiSelect-select': {
                      padding: '15px 20px',
                      minHeight: 'unset',
                    },
                  }
                : { backgroundColor: midnightDarker }),
            }}
            value={selectedTopicName}
          >
            {Object.keys(topics).map((key) => {
              const topic = topics[key];
              return (
                <MenuItem
                  key={`topic_${key}`}
                  selected={selectedTopicKey === key}
                  sx={{
                    color: isAP2Enabled ? midnight : eggshell,
                    fontFamily: 'Rota',
                    fontWeight: 600,
                    fontSize: '16px',
                    backgroundColor:
                      selectedTopicKey === key
                        ? 'rgba(144, 202, 249, 0.16) !important'
                        : 'transparent',
                    '&:hover': {
                      background: isAP2Enabled
                        ? 'rgba(144, 202, 249, 0.16)'
                        : midnight,
                    },
                  }}
                  value={key}
                >
                  <Stack
                    alignItems="center"
                    flexDirection="row"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                  >
                    {topic.name}
                    {selectedTopicKey === topic.topicName ? (
                      <CheckIcon />
                    ) : null}
                  </Stack>
                </MenuItem>
              );
            })}
            {isAP2Enabled ? null : (
              <>
                <Divider
                  sx={{
                    mx: 1.5,
                    borderColor: isAP2Enabled
                      ? cloud
                      : 'rgba(255, 255, 255, 0.12)',
                    borderWidth: isAP2Enabled ? '1.5px' : '1px',
                  }}
                />
                <Typography
                  sx={{
                    color: dusk,
                    fontFamily: 'Rota',
                    fontWeight: 600,
                    fontSize: '16x',
                    lineHeight: '20px',
                    mt: 2,
                    mx: 2,
                  }}
                >
                  Need a new custom topic? Contact your Notifi account manager
                  to add or edit custom topics
                </Typography>
              </>
            )}
          </Select>
        </FormControl>
        <InputLabel
          sx={{
            textAlign: 'right',
            fontSize: '12px',
            color: isAP2Enabled ? dusk : heather,
            fontFamily: 'Rota',
            fontWeight: 600,
          }}
        >
          max 32 characters
        </InputLabel>
        <TextField
          fullWidth
          inputProps={{
            autoComplete: 'off',
            maxLength: 32,
          }}
          InputProps={{
            endAdornment: (
              <>
                <NotifiTooltip
                  tooltipContent="The Campaign Name is for your internal use only. When you view reporting, you can filter messages by campaign."
                  boxSx={{
                    height: '100px',
                    width: '220px',
                    color: midnight,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pl: '20px',
                    borderRadius: '8px',
                  }}
                />
              </>
            ),
          }}
          onChange={(e) => {
            setCampaignId(e.target.value);
            clearMessageBarState();
          }}
          label="Campaign Name"
          sx={{
            '& input': {
              fontFamily: 'Rota',
              fontWeight: 600,
            },
            mb: 3,
          }}
          value={campaignId}
        />
        <DestinationSelectButtons
          emailSelected={emailSelected}
          setEmailSelected={setEmailSelected}
          SMSSelected={SMSSelected}
          setSMSSelected={setSMSSelected}
          telegramSelected={telegramSelected}
          setTelegramSelected={setTelegramSelected}
          discordSelected={discordSelected}
          setDiscordSelected={setDiscordSelected}
          setAlertHistorySelected={setAlertHistorySelected}
        />
        <Divider
          sx={{
            borderColor: isAP2Enabled ? cloud : 'rgba(255, 255, 255, 0.12)',
            borderWidth: isAP2Enabled ? '1.5px' : '1px',
          }}
        />
        {alertHistorySelected ? (
          <AlertHistoryComposer
            alertHistorySubject={alertHistorySubject}
            setAlertHistorySubject={setAlertHistorySubject}
            setAlertHistoryMessageContent={setAlertHistoryMessageContent}
            alertHistoryMessageContent={alertHistoryMessageContent}
            selectedTopicKey={selectedTopicKey}
            isCommunityManager={true}
          />
        ) : null}
        {emailSelected ? (
          <EmailComposer
            selectedFormat={selectedFormat}
            setSelectedFormat={setSelectedFormat}
            subject={subject}
            setSubject={setSubject}
            emailMessageContent={emailMessageContent}
            setEmailMessageContent={setEmailMessageContent}
            selectedTopicKey={selectedTopicKey}
            customHTML={customHTML}
            setCustomHTML={setCustomHTML}
            unstyledMessage={unstyledMessage}
            setUnstyledMessage={setUnstyledMessage}
            isCommunityManager={true}
          />
        ) : null}
        {SMSSelected ? (
          <SMSComposer
            setSMSMessageContent={setSMSMessageContent}
            SMSMessageContent={SMSMessageContent}
            selectedTopicKey={selectedTopicKey}
            isCommunityManager={true}
          />
        ) : null}
        {telegramSelected ? (
          <TelegramComposer
            telegramMessageContent={telegramMessageContent}
            setTelegramMessageContent={setTelegramMessageContent}
            selectedTopicKey={selectedTopicKey}
            isCommunityManager={true}
          />
        ) : null}
        {discordSelected ? (
          <DiscordComposer
            discordMessageContent={discordMessageContent}
            setDiscordMessageContent={setDiscordMessageContent}
            selectedTopicKey={selectedTopicKey}
            isCommunityManager={true}
          />
        ) : null}
        <Stack flexDirection="row" justifyContent="end">
          <Stack flexDirection="row" justifyContent="center">
            {loading ? (
              <Button variant="contained" sx={{ my: 2, width: '188px' }}>
                <CircularProgress size="30px" />
              </Button>
            ) : (
              <PrimaryButton
                disabled={!isEnabled}
                onClick={handlePublish}
                customSx={{
                  my: 2,
                  width: '188px',
                  transition: 'all 0.25s',
                  boxShadow: 'none',
                }}
                buttonTitle="Publish"
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      <ConfirmDialog
        ctaTitle="Got it"
        handleClose={() => setIsSuccessModalOpen(false)}
        handleConfirm={() => setIsSuccessModalOpen(false)}
        hideSecondaryAction={true}
        open={isSuccessModalOpen}
        sx={{
          backgroundColor: isAP2Enabled ? eggshell : midnightLight,
        }}
        titleSx={{ color: isAP2Enabled ? midnight : white }}
        title="Your message has been sent"
      />
    </Stack>
  );
};

export default CreateMessageForm;
