import React, { useRef, useState } from 'react';
import { Box, SvgIconTypeMap } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LeftMenuButton } from './LeftMenuButton';
import { coral, heather } from 'src/constants/colors';
import { ActiveMenuName } from './NavDrawer';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useAppContext } from 'src/context/AppContext';
import { CalloutTooltip } from '@components/alertManager/CalloutTooltip';
import { useFormMessageBarContext } from '@components/cardConfig/form/context/FormMessageBarContext';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import useClickOutside from 'src/hooks/useClickOutside';

type NavDrawItemProps = {
  ActiveIcon?:
    | OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  activeMenu: ActiveMenuName | undefined;
  activeName: ActiveMenuName;
  Icon?:
    | OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  name: string;
  path: string;
  setActiveMenu: React.Dispatch<
    React.SetStateAction<ActiveMenuName | undefined>
  >;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenNewWindow?: boolean;
};

export const NavDrawerItem: React.FC<NavDrawItemProps> = ({
  ActiveIcon,
  activeMenu,
  activeName,
  Icon,
  name,
  path,
  setActiveMenu,
  setOpen,
  isOpenNewWindow,
}: NavDrawItemProps) => {
  const communityManagerRef = useRef<HTMLDivElement>(null);
  const { isAP2Enabled } = useFeatureFlag();
  const { clearMessageBarState } = useFormMessageBarContext();
  const {
    state: { hasUnsavedChange },
  } = useAppContext();
  const navigate = useNavigate();

  const hasSeenCMCardTooltip = localStorage.getItem('hasSeenCMCardTooltip');
  const hasSeenImplementUICardTooltip = localStorage.getItem(
    'hasSeenImplementUICardTooltip',
  );
  const [isShowTooltip, setShowToolTip] = useState(true)

  useClickOutside(communityManagerRef, function () {
    setShowToolTip(false)
    localStorage.setItem('hasSeenCMCardTooltip', JSON.stringify(true));
  });

  const handleClick = (page: string, activeName: ActiveMenuName) => {
    clearMessageBarState();
    if (isAP2Enabled && isOpenNewWindow) {
      window.open(page, '_blank');
      return;
    }
    if (hasUnsavedChange) {
      setOpen(true);
      return;
    }
    setActiveMenu(activeName);
    navigate(page);
  };

  const hasIcon = Icon !== undefined;

  return (
    <>
      {name === 'Community Manager' &&
      !hasSeenCMCardTooltip &&
      hasSeenImplementUICardTooltip && isShowTooltip ? (
        <LeftMenuButton
          handleClick={() => handleClick(path, activeName)}
          icon={
            activeMenu === activeName && ActiveIcon ? (
              <ActiveIcon sx={{ color: coral }} />
            ) : hasIcon ? (
              <Icon
                sx={{
                  color: activeMenu === activeName ? coral : heather,
                  width: '18px',
                }}
              />
            ) : null
          }
          isActive={activeMenu === activeName}
          key={name}
          size="sm"
        >
          <Box ref={communityManagerRef}>
            <CalloutTooltip
              title="Learn how to use Community Manager"
              subtitle="As soon as you have some subscribers, send out an announcement in Community Manager."
              isTooltipOpen={isShowTooltip}
              buttonText="Community Manager"
              placement={'right'}
              onClick={async () => {
                navigate('/community');
                localStorage.setItem(
                  'hasSeenCMCardTooltip',
                  JSON.stringify(true),
                );
              }}
            >
              <span style={{ paddingRight: '20px' }}>{name}</span>
            </CalloutTooltip>
          </Box>
        </LeftMenuButton>
      ) : (
        <LeftMenuButton
          handleClick={() => handleClick(path, activeName)}
          icon={
            activeMenu === activeName && ActiveIcon ? (
              <ActiveIcon sx={{ color: coral }} />
            ) : hasIcon ? (
              <Icon
                sx={{
                  color: activeMenu === activeName ? coral : heather,
                  width: '18px',
                }}
              />
            ) : null
          }
          isActive={activeMenu === activeName}
          key={name}
          size="sm"
        >
          {name}
        </LeftMenuButton>
      )}
    </>
  );
};
