import {
  InputBaseComponentProps,
  InputBaseProps,
  SxProps,
  TextField,
} from '@mui/material';
import React from 'react';
import { dusk, midnightDarker } from 'src/constants/colors';

type Props = {
  customSx?: SxProps;
  disabled?: boolean;
  inputSx?: React.CSSProperties;
  label: string;
  onChange?: (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  value: string;
  placeholder?: string;
  InputProps?: InputBaseProps;
  inputAttrProps?: InputBaseComponentProps;
  onBlur?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const InputField: React.FC<Props> = ({
  customSx,
  disabled,
  inputSx,
  label,
  onChange,
  value,
  placeholder,
  InputProps,
  inputAttrProps,
  onBlur,
  onKeyDown,
}) => {
  return (
    <TextField
      onBlur={onBlur}
      disabled={disabled}
      onKeyDown={onKeyDown}
      fullWidth
      label={label}
      margin="dense"
      onChange={onChange && onChange}
      placeholder={placeholder}
      InputLabelProps={{
        style: { color: dusk, fontFamily: 'Rota', ...inputSx },
      }}
      inputProps={inputAttrProps}
      InputProps={InputProps}
      sx={{
        backgroundColor: midnightDarker,
        ...customSx,
      }}
      value={value}
    />
  );
};
