import React from 'react';
import { Stack } from '@mui/material';
import { CardConfigDataContextProvider } from '@components/cardConfig/CardConfigDataContext';

import { TopicsDataContextProvider } from '@components/alertManager/TopicsDataContext';

import { Outlet } from 'react-router-dom';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import { OnOffChainFormContextProvider } from '@components/alertManager/OnOffChainFormContext';

export const AlertManager: React.FC = () => {
  const { loaded } = useFeatureFlag();
  return (
    <CardConfigDataContextProvider>
      <TopicsDataContextProvider>
        <OnOffChainFormContextProvider>
          {loaded ? (
            <Stack marginX="5%" sx={{ position: 'relative', my: '48px' }}>
              <Outlet />
            </Stack>
          ) : null}
        </OnOffChainFormContextProvider>
      </TopicsDataContextProvider>
    </CardConfigDataContextProvider>
  );
};
