import { FetchResult, useMutation, useQuery } from '@apollo/client';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
} from 'react';
import { ROLES } from './constants';
import { AddUserMutation } from 'src/services/gql/mutations/AddUserMutation.gql';
import {
  AddUserMutation as AddUserMutationResponse,
  RemoveUserRolesMutation as RemoveUserRolesMutationResponse,
  UserProfileQuery as UserProfileQueryResponse,
  AddBotMutation as AddBotMutationResponse,
  RemoveBotMutation as RemoveBotMutationResponse,
  BotsByTypeQuery as BotsByTypeResponse,
  BotType,
  useAddBotMutation,
  useRemoveBotMutation,
  useAllEmailSendersQuery,
  useAddEmailSenderDomainMutation,
  AddEmailSenderDomainMutation as AddEmailSenderDomainMutationResponse,
  useRemoveEmailSenderDomainMutation,
  RemoveEmailSenderDomainMutation as RemoveEmailSenderDomainMutationResponse,
  useUpdateDefaultEmailSenderDomainMutation,
  UpdateDefaultEmailSenderDomainMutation as UpdateDefaultEmailSenderDomainResponse,
  useUpdateDefaultEmailSenderDisplayNameMutation,
  UpdateDefaultEmailSenderDisplayNameMutation as UpdateDefaultEmailSenderDisplayNameResponse,
  useUpdateDefaultEmailSenderNameMutation,
  UpdateDefaultEmailSenderNameMutation as UpdateDefaultEmailSenderNameResponse,
  useGetEmailSenderDefaultsQuery,
  useCreateDiscordBotConfigurationMutation,
  CreateDiscordBotConfigurationMutation as CreateDiscordBotConfigurationMutationResponse,
  useTenantDiscordBotConfigurationQuery,
  Totp2FaRequiredForEmailLoginsQuery,
  Is2faQuery,
} from 'src/services/gql/generated';
import { RemoveUserRoles } from 'src/services/gql/mutations/RemoveUserRoleMutation.gql';
import {
  TenantUsersQuery,
  TenantUsersResponse,
} from 'src/services/gql/queries/TenantUsersQuery.gql';
import { UserProfileQuery } from 'src/services/gql/queries/UserProfileQuery.gql';
import { BotsByTypeQuery } from 'src/services/gql/queries/BotsByTypeQuery.gql';
import { AllEmailSendersQuery } from 'src/services/gql/queries/AllEmailSendersQuery.gql';
import { EmailSenderDefaultsQuery } from 'src/services/gql/queries/GetEmailSenderDefaultsQuery.gql';
import { totp2FARequiredForEmailLoginsQuery } from 'src/services/gql/queries/totp2FARequiredForEmailLoginsQuery.gql';
import { is2faQuery } from 'src/services/gql/queries/Is2FaQuery.gql';

export type User = {
  email: string;
  roles: string[];
};

export type Bot = {
  id: string;
};

export type EmailDomainSender = {
  id: string | undefined;
};

type AccountSettingsDataContextState = Readonly<{
  createOrUpdateUser: (
    email: string,
    roles: string[],
  ) => Promise<FetchResult<AddUserMutationResponse>>;
  removeUser: (
    email: string,
    roles: string[],
  ) => Promise<FetchResult<RemoveUserRolesMutationResponse>>;
  createOrUpdateBot: (
    credentials: string,
    botType: string,
    name: string,
  ) => Promise<FetchResult<AddBotMutationResponse>>;
  removeBot: (id: string) => Promise<FetchResult<RemoveBotMutationResponse>>;
  createOrUpdateDomain: (
    domainName: string,
  ) => Promise<FetchResult<AddEmailSenderDomainMutationResponse>>;
  createDomainLoading: boolean;
  updateDefaultDomain: (
    id: string,
  ) => Promise<FetchResult<RemoveEmailSenderDomainMutationResponse>>;
  removeDomain: (
    id: string,
  ) => Promise<FetchResult<UpdateDefaultEmailSenderDomainResponse>>;
  updateDefaultDisplayName: (
    defaultDisplayName: string,
  ) => Promise<FetchResult<UpdateDefaultEmailSenderDisplayNameResponse>>;
  updateDefaultName: (
    defaultName: string,
  ) => Promise<FetchResult<UpdateDefaultEmailSenderNameResponse>>;
  createDiscordBotConfiguration: (
    discordClientId: string,
    discordClientSecret: string,
    discordGuildId: string,
    discordServerInviteLink: string,
    redirectUrl: string,
  ) => Promise<FetchResult<CreateDiscordBotConfigurationMutationResponse>>;
}>;

const AccountSettingsDataContext =
  createContext<AccountSettingsDataContextState>({
    createOrUpdateUser: () => Promise.reject('Uninitialized'),
    removeUser: () => Promise.reject('Uninitialized'),
    createOrUpdateBot: () => Promise.reject('Uninitialized'),
    removeBot: () => Promise.reject('Uninitialized'),
    createOrUpdateDomain: () => Promise.reject('Uninitialized'),
    createDomainLoading: false,
    updateDefaultDomain: () => Promise.reject('Uninitialized'),
    removeDomain: () => Promise.reject('Uninitialized'),
    updateDefaultDisplayName: () => Promise.reject('Uninitialized'),
    updateDefaultName: () => Promise.reject('Uninitialized'),
    createDiscordBotConfiguration: () => Promise.reject('Uninitialized'),
  });

export const useGetTenantUsersData = () => {
  const { data } = useQuery<TenantUsersResponse>(TenantUsersQuery, {
    variables: {
      filterRoles: [
        ROLES.admin.value,
        ROLES.developer.value,
        ROLES.marketer.value,
      ],
    },
  });

  return { data };
};

export const useGetUserProfile = () => {
  const { data, loading } =
    useQuery<UserProfileQueryResponse>(UserProfileQuery);
  return { data, loading };
};

export const useGet2FAStatus = () => {
  const { data, loading } =
    useQuery<Totp2FaRequiredForEmailLoginsQuery>(totp2FARequiredForEmailLoginsQuery);
  return { data, loading };
};

export const use2FASetupCheck = () => {
  const { data, loading } =
    useQuery<Is2faQuery>(is2faQuery);
  return { data, loading };
};



export const useGetBotsData = (botType: BotType) => {
  const { data } = useQuery<BotsByTypeResponse>(BotsByTypeQuery, {
    variables: {
      GetBotsByTypeInput: {
        botType,
      },
    },
  });

  return { data };
};

export const useGetRedirectUrl = () => {
  const { data } = useTenantDiscordBotConfigurationQuery();
  const redirectUrl = data?.tenantDiscordBotConfiguration?.redirectUrl ?? '';
  return { redirectUrl };
};

export const useGetAllEmailSenders = () => {
  const { data } = useAllEmailSendersQuery();
  return { data };
};

export const useGetEmailSenderDefaults = () => {
  const { data } = useGetEmailSenderDefaultsQuery();
  return { data: data?.emailSenderDefaults };
};

export const AccountSettingsDataContextProvider: React.FC<
  PropsWithChildren<Record<string, unknown>>
> = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
  const [createBotMutation] = useAddBotMutation();
  const [removeBotMutation] = useRemoveBotMutation();
  const [createEmailSenderDomainMutation, { loading: createDomainLoading }] =
    useAddEmailSenderDomainMutation();
  const [removeEmailSenderDomainMutation] =
    useRemoveEmailSenderDomainMutation();
  const [updateDefaultEmailSenderDomainMutation] =
    useUpdateDefaultEmailSenderDomainMutation();
  const [updateDefaultEmailSenderDisplayNameMutation] =
    useUpdateDefaultEmailSenderDisplayNameMutation();
  const [updateDefaultEmailSenderNameMutation] =
    useUpdateDefaultEmailSenderNameMutation();
  const [createDiscordConfiguration] =
    useCreateDiscordBotConfigurationMutation();

  const [createImpl] = useMutation<
    AddUserMutationResponse,
    { email: string; roles: string[] }
  >(AddUserMutation, {
    refetchQueries: [
      {
        query: TenantUsersQuery,
        variables: {
          filterRoles: [
            ROLES.admin.value,
            ROLES.developer.value,
            ROLES.marketer.value,
          ],
        },
      },
    ],
  });

  const [removeImpl] = useMutation<
    RemoveUserRolesMutationResponse,
    { email: string; roles: string[] }
  >(RemoveUserRoles, {
    refetchQueries: [
      {
        query: TenantUsersQuery,
        variables: {
          filterRoles: [
            ROLES.admin.value,
            ROLES.developer.value,
            ROLES.marketer.value,
          ],
        },
      },
    ],
  });

  const createOrUpdateUser = useCallback(
    (email: string, roles: string[]) => {
      return createImpl({
        variables: {
          email,
          roles,
        },
      }).then((r) => r);
    },
    [createImpl],
  );

  const removeUser = useCallback(
    (email: string, roles: string[]) => {
      return removeImpl({
        variables: {
          email,
          roles,
        },
      }).then((r) => r);
    },
    [removeImpl],
  );

  const removeBot = useCallback(
    (id: string) => {
      return removeBotMutation({
        variables: { id },
        refetchQueries: [
          {
            query: BotsByTypeQuery,
            variables: {
              GetBotsByTypeInput: {
                botType: BotType.TELEGRAM,
              },
            },
          },
          {
            query: BotsByTypeQuery,
            variables: {
              GetBotsByTypeInput: {
                botType: BotType.DISCORD,
              },
            },
          },
        ],
      }).then((r) => r);
    },
    [removeBotMutation],
  );

  const createOrUpdateBot = useCallback(
    (credentials: string, botType: string, name: string) => {
      return createBotMutation({
        variables: {
          botCredentials: credentials,
          botType: botType as BotType,
          name,
        },
        refetchQueries: [
          {
            query: BotsByTypeQuery,
            variables: {
              GetBotsByTypeInput: {
                botType: BotType.TELEGRAM,
              },
            },
          },
          {
            query: BotsByTypeQuery,
            variables: {
              GetBotsByTypeInput: {
                botType: BotType.DISCORD,
              },
            },
          },
        ],
      }).then((r) => r);
    },
    [createBotMutation],
  );

  const createDiscordBotConfiguration = useCallback(
    (
      discordClientId: string,
      discordClientSecret: string,
      discordGuildId: string,
      discordServerInviteLink: string,
      redirectUrl: string,
    ) => {
      return createDiscordConfiguration({
        variables: {
          discordClientId,
          discordClientSecret,
          discordGuildId,
          discordServerInviteLink,
          redirectUrl,
        },
        refetchQueries: [
          {
            query: BotsByTypeQuery,
            variables: {
              GetBotsByTypeInput: {
                botType: BotType.TELEGRAM,
              },
            },
          },
          {
            query: BotsByTypeQuery,
            variables: {
              GetBotsByTypeInput: {
                botType: BotType.DISCORD,
              },
            },
          },
        ],
      }).then((r) => r);
    },
    [createDiscordConfiguration],
  );

  const createOrUpdateDomain = useCallback(
    (domainName: string) => {
      return createEmailSenderDomainMutation({
        variables: {
          domainName,
        },
        refetchQueries: [
          {
            query: AllEmailSendersQuery,
          },
        ],
      }).then((r) => r);
    },
    [createEmailSenderDomainMutation],
  );

  const updateDefaultDomain = useCallback(
    (id: string) => {
      return updateDefaultEmailSenderDomainMutation({
        variables: { defaultDomainId: id },
        refetchQueries: [
          {
            query: AllEmailSendersQuery,
          },
          {
            query: EmailSenderDefaultsQuery,
          },
        ],
      })
        .then((r) => r)
        .catch((error) => {
          return error;
        });
    },
    [updateDefaultEmailSenderDomainMutation],
  );

  const updateDefaultDisplayName = useCallback(
    (displayName: string) => {
      return updateDefaultEmailSenderDisplayNameMutation({
        variables: { defaultDisplayName: displayName },
        refetchQueries: [
          {
            query: AllEmailSendersQuery,
          },
          {
            query: EmailSenderDefaultsQuery,
          },
        ],
      })
        .then((r) => r)
        .catch((error) => {
          return error;
        });
    },
    [updateDefaultEmailSenderDisplayNameMutation],
  );

  const updateDefaultName = useCallback(
    (name: string) => {
      return updateDefaultEmailSenderNameMutation({
        variables: { defaultName: name },
        refetchQueries: [
          {
            query: AllEmailSendersQuery,
          },
          {
            query: EmailSenderDefaultsQuery,
          },
        ],
      })
        .then((r) => r)
        .catch((error) => {
          return error;
        });
    },
    [updateDefaultEmailSenderNameMutation],
  );

  const removeDomain = useCallback(
    (id: string) => {
      return removeEmailSenderDomainMutation({
        variables: { domainNameId: id },
        refetchQueries: [
          {
            query: AllEmailSendersQuery,
          },
        ],
      }).then((r) => r);
    },
    [removeEmailSenderDomainMutation],
  );

  return (
    <AccountSettingsDataContext.Provider
      value={{
        createOrUpdateUser,
        removeUser,
        createOrUpdateBot,
        removeBot,
        createOrUpdateDomain,
        createDomainLoading,
        updateDefaultDomain,
        removeDomain,
        updateDefaultDisplayName,
        updateDefaultName,
        createDiscordBotConfiguration,
      }}
    >
      {children}
    </AccountSettingsDataContext.Provider>
  );
};

export const useAccountSettingsDataContext =
  (): AccountSettingsDataContextState => {
    return useContext(AccountSettingsDataContext);
  };
