import { FormMessageBarProvider } from '@components/cardConfig/form/context/FormMessageBarContext';
import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import {
  AppContextAction,
  appContextReducer,
  AppState,
} from './appContextReducer';

type AppContextState = Readonly<{
  state: AppState;
  dispatch: Dispatch<AppContextAction>;
}>;

const AppContext = createContext<AppContextState>({
  state: {} as AppState,
  dispatch: () => {
    throw new Error('Unimplemented');
  },
});

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(appContextReducer, {
    hasUnsavedChange: false,
  });

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <FormMessageBarProvider>{children}</FormMessageBarProvider>
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextState => {
  return useContext(AppContext);
};
