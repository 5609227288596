import { HasValueOrRef } from './ValueOrRef';

export type ContactInfo = Readonly<{
  active: boolean;
}>;

export type EmailContactInfo = ContactInfo;

export type DiscordContactInfo = ContactInfo;

export type WhatsAppContactInfo = ContactInfo;

export type CountryCode = string;

export type SmsContactInfo = ContactInfo &
  Readonly<{
    supportedCountryCodes: ReadonlyArray<CountryCode>;
  }>;

export type TelegramContactInfo = ContactInfo;

export type WebhookHeaders = Readonly<Record<string, string>>;

export type WebhookContactInfo = ContactInfo &
  HasValueOrRef<'url', string> &
  HasValueOrRef<'headers', WebhookHeaders>;

export type ContactInfoConfig = Readonly<{
  email: EmailContactInfo;
  sms: SmsContactInfo;
  telegram: TelegramContactInfo;
  // remove potentially undefined after discord is fully merged in.
  discord?: DiscordContactInfo;
  whatsapp?: WhatsAppContactInfo;
}>;

export const DEFAULT_CONTACT_INFO_CONFIG: ContactInfoConfig = {
  email: {
    active: true,
  },
  sms: {
    active: false,
    supportedCountryCodes: [],
  },
  telegram: {
    active: false,
  },
  // uncomment after discord is fully merged in and ready

  // discord: {
  //   active: false
  // }
};
