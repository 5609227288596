import {
  Box,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { seaglassDarker, error, dusk, blueglass } from 'src/constants/colors';
import { ConfirmDialog } from '@components/cardConfig/ConfirmDialog';
import { ReactComponent as IconClose } from '../../../assets/icn-cancel.svg';
import {
  FusionSourceState,
  FusionSourceWithCursor,
  useActivateFusionSourceMutation,
  useDeactivateFusionSourceMutation,
} from 'src/services/gql/generated';
import { useGetFusionTopicsData } from '@components/alertManager/TopicsDataContext';

type Props = {
  fusionSource: FusionSourceWithCursor;
};

const ParserStatus: React.FC<Props> = ({ fusionSource }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeValue, setActiveValue] = useState(
    fusionSource?.cursor?.cursorValue ?? '',
  );
  const [parserRequestState, setParserRequestState] = useState<
    FusionSourceState.ACTIVE | FusionSourceState.INACTIVE
  >();

  const [deactivateFusionSource, { loading: deactivatingFusionSource }] =
    useDeactivateFusionSourceMutation();
  const [activateFusionSource, { loading: activatingFusionSource }] =
    useActivateFusionSourceMutation();

  const { refetchFusionTopicsData } = useGetFusionTopicsData();
  const isLoading = deactivatingFusionSource || activatingFusionSource;

  useEffect(() => {
    setActiveValue(fusionSource?.cursor?.cursorValue ?? '');
  }, [fusionSource]);

  const handleSubmit = useCallback(async () => {
    if (!fusionSource || !activeValue) return;

    try {
      await activateFusionSource({
        variables: {
          fusionSourceId: fusionSource.id,
          cursorValue: activeValue,
        },
      });

      setParserRequestState(FusionSourceState.ACTIVE);
      handleCloseModal();
    } catch (e) {
      //
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeValue, fusionSource, activateFusionSource]);

  const handleDeactivate = useCallback(async () => {
    if (!fusionSource) return;

    try {
      await deactivateFusionSource({
        variables: {
          fusionSourceId: fusionSource.id,
        },
      });

      setParserRequestState(FusionSourceState.INACTIVE);
    } catch (e) {
      //
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fusionSource, deactivateFusionSource]);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const refetchData = () => {
    setTimeout(() => {
      refetchFusionTopicsData();
    }, 5000);
  };

  useEffect(() => {
    if (!parserRequestState) return;

    if (fusionSource.cursor?.sourceState === parserRequestState) {
      setParserRequestState(undefined);
    } else {
      refetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parserRequestState, fusionSource]);

  let UI = null;
  if (fusionSource?.version < 1) UI = null;
  else if (parserRequestState) {
    const text =
      parserRequestState === 'ACTIVE'
        ? 'Parser activation in progress'
        : 'Parser deactivation in progress';

    UI = (
      <Stack direction="row" alignItems="center">
        <Stack
          sx={{
            backgroundColor: '#E9E9F8',
            p: '8px 12px',
            borderRadius: '4px',
            alignItems: 'center',
            justifyContent: 'center',
            mr: '15px',
          }}
        >
          <Typography
            sx={{ color: seaglassDarker, fontSize: '13px', fontWeight: 800 }}
          >
            {text}
          </Typography>
        </Stack>
      </Stack>
    );
  } else if (fusionSource?.cursor?.sourceState === 'ERROR') {
    UI = (
      <Stack direction="row" alignItems="center">
        <Stack
          sx={{
            backgroundColor: '#F2E1E1',
            p: '8px 12px',
            borderRadius: '4px',
            alignItems: 'center',
            justifyContent: 'center',
            mr: '15px',
          }}
        >
          <Typography sx={{ color: error, fontSize: '13px', fontWeight: 800 }}>
            Error at block
            {/*{toEllipse(fusionSource?.cursor?.cursorValue, 4, 4)}*/}
          </Typography>
        </Stack>
        <Typography
          sx={{
            color: seaglassDarker,
            fontSize: '13px',
            fontWeight: 800,
            cursor: 'pointer',
          }}
          onClick={() => setIsOpen(true)}
        >
          Reactivate
        </Typography>
      </Stack>
    );
  } else if (fusionSource?.cursor?.sourceState === 'PROCESSING') {
    UI = (
      <Stack direction="row" alignItems="center">
        <Stack
          sx={{
            backgroundColor: '#E9E9F8',
            p: '8px 12px',
            borderRadius: '4px',
            alignItems: 'center',
            justifyContent: 'center',
            mr: '15px',
          }}
        >
          <Typography
            sx={{ color: seaglassDarker, fontSize: '13px', fontWeight: 800 }}
          >
            Processing
          </Typography>
        </Stack>

        <Typography
          sx={{
            color: seaglassDarker,
            fontSize: '13px',
            fontWeight: 800,
            cursor: 'pointer',
          }}
          onClick={() => handleDeactivate()}
        >
          Deactivate
        </Typography>
      </Stack>
    );
  } else {
    const isActive = fusionSource?.cursor?.sourceState === 'ACTIVE';

    UI = (
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="active"
        name="radio-buttons-group"
        sx={{ display: 'flex', flexDirection: 'row' }}
      >
        <FormControlLabel
          value="active"
          checked={isActive}
          label={
            <Typography sx={{ mt: '2px', fontSize: '13px', fontWeight: 800 }}>
              Active
            </Typography>
          }
          sx={{
            '& .MuiButtonBase-root.Mui-checked': { color: blueglass },
          }}
          control={<Radio />}
          onChange={() => setIsOpen(true)}
        />

        <FormControlLabel
          value="inactive"
          checked={!isActive}
          label={
            <Typography sx={{ mt: '2px', fontSize: '13px', fontWeight: 800 }}>
              Inactive
            </Typography>
          }
          sx={{
            '& .MuiButtonBase-root.Mui-checked': { color: blueglass },
          }}
          control={<Radio />}
          onChange={() => handleDeactivate()}
        />
      </RadioGroup>
    );
  }

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size="30px" thickness={3} />
        </Box>
      ) : (
        UI
      )}

      <ConfirmDialog
        title="Set parser to active"
        open={isOpen}
        handleClose={handleCloseModal}
        handleConfirm={handleSubmit}
        ctaTitle="Activate Parser"
        sx={{ width: '600px' }}
        disabled={isLoading}
      >
        <Box
          sx={{
            position: 'absolute',
            right: '50px',
            top: '30px',
            width: '15px',
            height: '15px',
            cursor: 'pointer',
          }}
          onClick={handleCloseModal}
        >
          <IconClose />
        </Box>

        <Stack sx={{ alignItems: 'center', justifyContent: 'center', mb: 4 }}>
          <Typography
            sx={{
              color: dusk,
              fontFamily: 'Rota',
              fontSize: '16px',
              fontWeight: 600,
            }}
          >
            Must be within 1,000 blocks from current
          </Typography>
        </Stack>

        <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <TextField
            sx={{
              width: '360px',
            }}
            label={'Latest Block'}
            value={activeValue}
            onChange={(e) => {
              const value = (e.target as HTMLInputElement).value;
              setActiveValue(value);
            }}
          />
        </Stack>
      </ConfirmDialog>
    </>
  );
};

export default ParserStatus;
