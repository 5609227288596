export const cloud = '#F3F3FA';
export const coral = '#EA7E68';
export const dusk = '#80829D';
export const heather = '#B6B8D5';
export const midnight = '#262949';
export const midnightDarker = '#191B36';
export const seaglass = '#9AA3FF';
export const seaglassDarker = '#828AE3';
export const white = '#FFFFFF';
export const midnightLight = '#383B63';
export const midnightLightest = '#373B65';
export const eggshell = '#F5F6FB';
export const Sapphire = '#737BD2';
export const rose = '#C78186';
export const error = '#D34C4C';
export const cloudLight = '#E8EBF5';
export const light = '#FEFEFE';
export const blueglass = '#6D79FF';
export const copper =
  'linear-gradient(207.53deg, #FE7970 11.91%, #FEB776 88.99%)';
